import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Training } from 'src/app/core/models/interfaces/training';
import { TrainingsService } from 'src/app/core/services/trainings.service';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TrainingsComponent implements OnInit {
  isLoading: boolean = true;
  trainings: Training[] = [];
  columnsToDisplay = ['CUSTOMER_ID', 'PRODUCT_ID', 'TRAINING_CREATE_DATE',
  'TRAINING_ID', 'TRAINING_NAME', 'TRAINING_STATUS', 'USER_BIRTHDAY', 'USER_NAME', 'USER_SEX', 'USER_SURNAME', 'VIEW'
];
  expandedElement: Training | null = null;

  constructor(
    private router: Router,
    private trainingsService: TrainingsService) { }

  ngOnInit(): void {
    this.getTrainings();
  }

  getTrainings() {
    this.isLoading = true;
    this.trainingsService.getAllTrainings().subscribe(data => {
      this.trainings = data.trainings;
      this.isLoading = false;
    });
  }

  refresh() {
    this.getTrainings();
  }

  DDD(data: string) {
    return atob(data);
  }

  openTraining(training: Training) {
    this.router.navigate(["home/training", training.TRAINING_ID]);
  }

}
