import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { IntensityResponse } from '../models/interfaces/intensity-response';

@Injectable({
  providedIn: 'root'
})
export class IntensityService {

  constructor(private http: HttpClient) { }

  getIntensityList() {
    return this.http.post<IntensityResponse>(API.USER.TRAINER.INTENSITY_LIST, null);
  }
}
