import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/classes/user';
import { WarmupDataGetRequest } from 'src/app/core/models/classes/warmup-data-get-request';
import { Warmup } from 'src/app/core/models/interfaces/warmup';
import { AccountService } from 'src/app/core/services/account.service';
import { WarmupDataService } from 'src/app/core/services/warmup-data.service';
import { WarmupsService } from 'src/app/core/services/warmups.service';

@Component({
  selector: 'app-warmup-list',
  templateUrl: './warmup-list.component.html',
  styleUrls: ['./warmup-list.component.scss']
})
export class WarmupListComponent implements OnInit {
  isLoading: boolean = true;
  warmups: Warmup[] = [];
  columnsToDisplay = ['WARMUP_NAME', 'VIEW'];
  user: User;

  constructor(
    private router: Router,
    private warmupsService: WarmupsService,
    private warmupDataService: WarmupDataService,
    private accountService: AccountService) {
    //   this.accountService.user.subscribe(user => {
    //   this.user = user

      
    // });


    this.user = JSON.parse(localStorage.getItem('user')!);

  }

  ngOnInit(): void {
    this.getWarmups();
  }

  getWarmups() {
    this.isLoading = true;
    const warmupDataGet = new WarmupDataGetRequest(this.user['id']);
    this.warmupsService.getTrainerWarmups(warmupDataGet).subscribe(data => {
      this.warmups = data.warmup_data;
      this.isLoading = false;
    });
  }

  refresh() {
    this.getWarmups();
  }

  add() {
    this.router.navigate(["home/warmup"]);
  }

  openTraining(warmup: Warmup) {
    this.warmupDataService.changeWarmupData(warmup);
    this.router.navigate(["home/warmup", warmup.ID]);
  }

}
