import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Warmup } from '../models/interfaces/warmup';

@Injectable({
  providedIn: 'root'
})
export class WarmupDataService {
  private warmupDataSubject: BehaviorSubject<Warmup>;
  public warmupData: Observable<Warmup>;

  private onClearDraftDataSubject: BehaviorSubject<boolean>;
  public onClearDraftData: Observable<boolean>;

  constructor() {
    this.warmupDataSubject = new BehaviorSubject<Warmup>({} as Warmup);
    this.warmupData = this.warmupDataSubject.asObservable();
    
    this.onClearDraftDataSubject = new BehaviorSubject<boolean>(false);
    this.onClearDraftData = this.onClearDraftDataSubject.asObservable();
  }

  changeWarmupData(warmupData: Warmup) {
    console.log(warmupData);
    this.warmupDataSubject.next(warmupData);
  }

  clearDraftData() {
    this.onClearDraftDataSubject.next(true);
  }

}
