export class LoginRequest {
  private mail: string;
  private password: string;
  private session_token: string = "";
  
  constructor(mail: string, password: string) {
    this.mail = mail;
    this.password = password;
  }

  set token(session_token: string) {
    this.session_token = session_token;
  }
}
