import { Component, OnChanges, OnInit } from '@angular/core';
import { Exercise } from 'src/app/core/models/interfaces/exercise';
import { ExercisesService } from 'src/app/core/services/exercises.service';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export const ABC: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

@Component({
  selector: 'app-setup-exercises',
  templateUrl: './setup-exercises.component.html',
  styleUrls: ['./setup-exercises.component.scss']
})
export class SetupExercisesComponent implements OnInit {
  isLoading: boolean = true;
  exercises: Exercise[] = [];
  columns = ['ID', 'EXERCISE_NAME', 'EXERCISE_CATEGORY_ID'];
  //columns = ['position', 'name', 'weight', 'symbol'];
  expandedElement: Exercise | null = null;
  dataSource = new MatTableDataSource(this.exercises);

  abc = ABC;
 

  constructor(private exercisesService: ExercisesService) { }

  ngOnInit(): void {
    this.getExercises();

    //  setInterval(() => {
    //   this.isLoading = !this.isLoading;
    // },3000)
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.exercises);
  }



  getExercises() {
    this.isLoading = true;
    this.exercisesService.getAllExercises().subscribe(data => {
      this.exercises = data.exercises;this.dataSource = new MatTableDataSource(this.exercises);
      this.isLoading = false;
    });
  }

  refresh() {
    this.isLoading = true;
     setTimeout(() => {
      this.getExercises();
    },100000)

  }


 
}
