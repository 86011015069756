import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly TRAINING_DATA = 'user/TRAINER_settrainingdata.php';
  private readonly TRAINING_DATA1 = 'user/TRAINER_gettrainingdata.php';
  private readonly TRAINING_DATA2 = 'user/TRAINER_settrainingstatus.php';
  private readonly WARMUPS_DATA = 'user/TRAINER_getwarmups.php';
  private readonly WARMUPS_DATA1 = 'user/TRAINER_setwarmup.php';
  private readonly TRAINER = 'user/TRAINER_';
  private readonly CONTENT_TYPE = 'Content-Type';
  private readonly APPLICATION_URLENCODED = 'application/x-www-form-urlencoded';

  constructor(private accountService: AccountService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url && (request.url.includes(this.TRAINING_DATA) ||
    request.url.includes(this.TRAINING_DATA1) ||
    request.url.includes(this.TRAINING_DATA2)) ||
    request.url.includes(this.WARMUPS_DATA) ||
    request.url.includes(this.WARMUPS_DATA1)
    ) {
      const request_data = request.body;
      const authRequest = request.clone({
        headers: new HttpHeaders().set(this.CONTENT_TYPE, this.APPLICATION_URLENCODED),
        body: { ...this.accountService.authCredentials, request_data }
      });
      return next.handle(authRequest);
    } else if (request.url && request.url.includes(this.TRAINER)) {
      const authRequest = request.clone({
        headers: new HttpHeaders().set(this.CONTENT_TYPE, this.APPLICATION_URLENCODED),
        body: this.accountService.authCredentials
      });
      return next.handle(authRequest);
    } else {
      return next.handle(request);
    }
  }
}
