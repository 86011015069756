import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuAction } from 'src/app/core/enums/menu-action.enum';
import { Entry } from 'src/app/core/enums/entry.enum';
import { TrainingStatus } from 'src/app/core/enums/training-status.enum';
import { TrainingData } from 'src/app/core/models/interfaces/training-data';
import { TrainingDataService } from 'src/app/core/services/training-data.service';
import { TrainingStateService } from 'src/app/core/services/training-state.service';

@Component({
  selector: 'app-training-menu',
  templateUrl: './training-menu.component.html',
  styleUrls: ['./training-menu.component.scss']
})
export class TrainingMenuComponent implements OnInit {
  trainingData?: TrainingData;
  isLoading: boolean = true;
  isSaving: boolean = false;
  isClosing: boolean = false;
  closeTimeLeft: number = 10000;
  closeProgress: number = 0;
  private closeCountdown: Subscription | undefined;

  public Entry = Entry;

  @Input() entry: Entry | undefined;
  @Output() menuActionEvent = new EventEmitter<MenuAction>();

  constructor(
    private trainingDataService: TrainingDataService,
    private trainingStateService: TrainingStateService) { }

  ngOnInit(): void {
    this.trainingDataService.trainingData.subscribe(trainingData => {
      this.trainingData = trainingData;
    });

    this.trainingStateService.isLoading().subscribe(loading => {
      this.isLoading = loading;
      // this.isLoading = false;
    });
    this.trainingStateService.isSaving().subscribe(saving => {
      this.isSaving = saving;
    });

  }

  isButtonDisabled(): boolean {
    return this.isLoading || this.isSaving || this.isTrainingStatusCompleted();
  }

  isTrainingStatusCompleted() {
    return TrainingStatus.COMPLETED == this.trainingData?.TRAINING_STATUS;
  }

  back() {
    this.menuActionEvent.emit(MenuAction.BACK);
  }

  reset() {
    this.menuActionEvent.emit(MenuAction.CLEAR);
  }

  restore() {
    this.menuActionEvent.emit(MenuAction.RESTORE);
  }

  save() {
    this.menuActionEvent.emit(MenuAction.SAVE);
  }

  done() {
    this.menuActionEvent.emit(MenuAction.DONE);
  }

}
