import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TrainingData } from '../models/interfaces/training-data';

@Injectable({
  providedIn: 'root'
})
export class TrainingDataService {
  private trainingDataSubject: BehaviorSubject<TrainingData>;
  public trainingData: Observable<TrainingData>;
  private numberOfTrainingDaysSubject: BehaviorSubject<number>;
  public numberOfTrainingDays: Observable<number>;

  
  private onClearDraftDataSubject: BehaviorSubject<boolean>;
  public onClearDraftData: Observable<boolean>;

  constructor() {
    this.trainingDataSubject = new BehaviorSubject<TrainingData>({} as TrainingData);
    this.trainingData = this.trainingDataSubject.asObservable();
    this.numberOfTrainingDaysSubject = new BehaviorSubject<number>(1);
    this.numberOfTrainingDays = this.numberOfTrainingDaysSubject.asObservable();
    
    this.onClearDraftDataSubject = new BehaviorSubject<boolean>(false);
    this.onClearDraftData = this.onClearDraftDataSubject.asObservable();
  }

  changeTrainingData(trainingData: TrainingData) {
    this.trainingDataSubject.next(trainingData);
  }

  changeNumberOfTrainingDays(value: number) {
    this.numberOfTrainingDaysSubject.next(value);
  }

  clearDraftData() {
    this.onClearDraftDataSubject.next(true);
  }

}
