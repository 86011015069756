<h2 mat-dialog-title> 
  <span>Unsaved changes</span>
</h2>
<mat-divider></mat-divider>
<br>
<mat-dialog-content class="mat-typography">
  <p>
    You have unsaved changes.
  </p>
  <!-- <br/> -->
  <p>
    Are you sure you want to leave this page?
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="false" color="warn">Stay on this Page</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Leave this Page</button>
</mat-dialog-actions>
