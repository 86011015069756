import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TrainingComponent } from 'src/app/home-page/components/training/training.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateTrainingGuard implements CanDeactivate<TrainingComponent> {
  canDeactivate(
    component: TrainingComponent): Observable<boolean> | boolean {
      return component.isContentChanged() ? component.openConfirmDialog() : true;
  }
  
}
