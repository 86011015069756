export class WarmupDataSetRequest {
  private warmupId: string;
  private trainerId: string;
  private warmupData: string; // base64
  private warmupName: string;

  constructor(warmupId: string, trainerId: string, warmupData: string, warmupName: string) {
    this.warmupId = warmupId;
    this.trainerId = trainerId;
    this.warmupData = warmupData;
    this.warmupName = warmupName;
  }
}
