import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetauthkeyRequest } from '../models/classes/getauthkey-request';
import { GetauthkeyResponse } from '../models/interfaces/getauthkey-response';
import { LoginRequest } from '../models/classes/login-request';
import { LoginResponse } from '../models/interfaces/login-response';
import { mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { SHA512} from 'crypto-js';
import { API } from '../constants/api';
import { ResponseStatus } from '../enums/response-status.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/classes/user';
import { LoginStatus } from '../enums/login-status.enum';
import { UserType } from '../enums/user-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly CONTENT_TYPE = 'Content-Type';
  private readonly APPLICATION_URLENCODED = 'application/x-www-form-urlencoded';
  
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private isAuthenticatedSubject: BehaviorSubject<boolean>;
  public isAuthenticated: Observable<boolean>;

  private authCredentialsSubject: BehaviorSubject<LoginRequest>;
  private loginStatusSubject: BehaviorSubject<LoginStatus>;
  public loginStatus: Observable<LoginStatus>;

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')!));
    this.authCredentialsSubject = new BehaviorSubject<LoginRequest>(JSON.parse(localStorage.getItem('authCredentials')!));
    this.loginStatusSubject = new BehaviorSubject<LoginStatus>(LoginStatus.LOGGED_OUT);
    this.loginStatus = this.loginStatusSubject.asObservable();
    this.user = this.userSubject.asObservable();
    this.isAuthenticatedSubject = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem('user')!));
    this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  public get authCredentials(): LoginRequest {
    return this.authCredentialsSubject.value;
  }
 
//     use multipart/form-data when your form includes any <input type="file"> elements
// otherwise you can use multipart/form-data or application/x-www-form-urlencoded but application/x-www-form-urlencoded will be more efficient

  generateInithash() {
    return SHA512(uuidv4()).toString();
  }

  hashPassword(password: string) {
    return SHA512(password).toString();
  }

  login(email: string, password: string) {
    const headers = new HttpHeaders().set(this.CONTENT_TYPE, this.APPLICATION_URLENCODED);
    const authBody = new GetauthkeyRequest(this.generateInithash());
    const loginBody = new LoginRequest(email, this.hashPassword(password));
    
    this.http.post<GetauthkeyResponse>(API.USER.AUTH, authBody, { headers })
    .pipe(
      mergeMap(authResponse => {
       if (ResponseStatus.OK === authResponse.response_status) {
         loginBody.token = authResponse.authkey;
         return this.http.post<LoginResponse>(API.USER.LOGIN, loginBody , { headers });
        }
         else {
          return of(authResponse);
        }
      })
    ).subscribe(response => {
        switch (response.response_status) {
          case ResponseStatus.SUCCESS:
            if ('authkey' in response) {
              console.log('failed to obtain token');
            } else if ('user_info' in response) {
              const userType = response.user_info.USER_TYPE;
              if (userType == UserType.TRAINER || userType == UserType.TRAINER_NUTRITIONIST) {
                const user = new User(response.user_info.ID,
                                      response.user_info.USER_TYPE,
                                      response.user_info.USER_NAME,
                                      response.user_info.USER_SURNAME);
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('authCredentials', JSON.stringify(loginBody));
                this.userSubject.next(user);
                this.isAuthenticatedSubject.next(true);
                this.loginStatusSubject.next(LoginStatus.LOGGED_IN);
              } else {
                this.loginStatusSubject.next(LoginStatus.BAD_USER_TYPE);
              }
            }
            break;
          case ResponseStatus.BAD_USER_OR_PASSWORD:
            this.loginStatusSubject.next(LoginStatus.BAD_USER_OR_PASSWORD);
            break;
          default:
            break;
        } 
    });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('authCredentials');
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['/login']);
  }
}
