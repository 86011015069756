import { Component, OnInit } from '@angular/core';
import { Category } from 'src/app/core/models/interfaces/category';
import { CategoriesService } from 'src/app/core/services/categories.service';

@Component({
  selector: 'app-setup-categories',
  templateUrl: './setup-categories.component.html',
  styleUrls: ['./setup-categories.component.scss']
})
export class SetupCategoriesComponent implements OnInit {
  isLoading: boolean = true;
  categories: Category[] = [];
  columns = ['ID', 'EXERCISE_CATEGORY_NAME'];

  constructor(private categoriesService: CategoriesService) { }

  ngOnInit(): void {
    this.getCategories();
  }
  
  getCategories() {
    this.isLoading = true;
    this.categoriesService.getAllCategories().subscribe(data => {
      this.categories = data.categories;
      this.isLoading = false;
    });
  }

  refresh() {
    this.isLoading = true;
     setTimeout(() => {
      this.getCategories();
    },1000)

    // this.getCategories();
  }

}
