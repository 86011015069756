<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
  <div fxFlex fxFlexFill fxLayout="column" fxlayoutAlign="start stretch">
    <div fxFlex="none" fxLayout="row" fxlayoutAlign="start stretch">
      <app-training-menu fxFlex
       [entry]='entry.TRAINING'
       (menuActionEvent)="handleMenuAction($event)">
      </app-training-menu>
    </div>
    <div fxFlex fxFlexFill fxLayout="row" class="scrollable">
      <!--  class="scrollable" -->
      <!-- <div fxFlex="30" fxLayout="column" fxlayoutAlign="start stretch" > -->
        <!-- <div [ngClass]="{'hide': showSidebar}" [fxFlex]="sidebarSize" fxLayout="column" fxlayoutAlign="start stretch" class="scrollable">-->
          <!-- [ngClass]="{'show': showSidebar, 'hide' : !showSidebar }" -->
        <div [ngClass]="{'show': showSidebar, 'hide' : !showSidebar }"
        
        [style.max-width]="sidebarSize"

         fxLayout="column" fxlayoutAlign="start stretch" class="scrollable"> 
          <!--  [ngStyle]="{'max-width':  showSidebar ? sidebarSize : '0%' }" -->
        

        <!-- class="scrollable" -->
        <app-exercises fxFlex>
          
        </app-exercises>
        <!-- <app-exercises fxFlex class="scrollable">
        </app-exercises>
        <div>
          asd
        </div>
        <app-exercises fxFlex class="scrollable">
        </app-exercises> -->

      </div>

      <!-- <div fxLayout="column" fxLayoutAlign="space-around center" fxFlex="0.8em"> -->
        <!-- <button mat-stroked-button fxFlex fxFlexFill class="toggbtn" fxLayoutAlign="center center" (click)="toggleSidebar()">
          <mat-icon *ngIf="showSidebar">arrow_left</mat-icon>
          <mat-icon *ngIf="!showSidebar">arrow_right</mat-icon>
        </button>
    
        <div fxLayoutAlign="center center">
          <mat-icon>more_vert</mat-icon>
        </div>
        
        <button mat-button fxFlex fxFlexFill class="toggbtn" fxLayoutAlign="center center">
          <mat-icon>arrow_left</mat-icon>
        </button> -->
        <div class="margin" >
          <button mat-stroked-button class="toggbtn" fxFlex="0.8em" fxFlexFill (click)="toggleSidebar()">
            <div  fxFlexFill fxLayout="column" fxLayoutAlign="space-around center"
            
            >
              <mat-icon *ngIf="showSidebar">arrow_left</mat-icon>
              <mat-icon *ngIf="!showSidebar">arrow_right</mat-icon>
    
              
              <mat-icon cdkDragHandle>more_vert</mat-icon>
              
              <mat-icon *ngIf="showSidebar">arrow_left</mat-icon>
              <mat-icon *ngIf="!showSidebar">arrow_right</mat-icon>
            </div>
          </button>
        </div>
      <!-- </div> -->


      <div fxFlex fxLayout="column" fxlayoutAlign="start stretch" class="scrollable">
        <!-- class="scrollable" -->
        <!-- <div fxFlex> -->
          
            <app-training-draft fxFlex>

              
            </app-training-draft>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
