import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { SeriesDelayResponse } from '../models/interfaces/series-delay-response';

@Injectable({
  providedIn: 'root'
})
export class SeriesDelayService {

  constructor(private http: HttpClient) { }

  getSeriesDelay() {
    return this.http.post<SeriesDelayResponse>(API.USER.TRAINER.SERIES_DELAY, null);
  }
}
