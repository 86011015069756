export class TrainingStatusSetRequest {
  private customerId: number;
  private trainingId: number;
  private trainingStatus: number;

  constructor(customerId: number, trainingId: number, trainingStatus: number) {
    this.customerId = customerId;
    this.trainingId = trainingId;
    this.trainingStatus = trainingStatus;
  }
}
