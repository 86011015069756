import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuAction } from 'src/app/core/enums/menu-action.enum';

@Component({
  selector: 'app-dialog-content-confirm-action',
  templateUrl: './dialog-content-confirm-action.component.html',
  styleUrls: ['./dialog-content-confirm-action.component.scss']
})
export class DialogContentConfirmActionComponent implements OnInit {
  actions: typeof MenuAction = MenuAction

  constructor(public dialogRef: MatDialogRef<DialogContentConfirmActionComponent>, @Inject(MAT_DIALOG_DATA) public action: MenuAction) { }

  ngOnInit(): void {
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  isClear() {
    return this.actions.CLEAR === this.action;
  }

  isRestore() {
    return this.actions.RESTORE === this.action;
  }

  isSave() {
    return this.actions.SAVE === this.action;
  }

  isDone() {
    return this.actions.DONE === this.action;
  } 
}
