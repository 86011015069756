import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginStatus } from 'src/app/core/enums/login-status.enum';

@Component({
  selector: 'app-dialog-content-login-status',
  templateUrl: './dialog-content-login-status.component.html',
  styleUrls: ['./dialog-content-login-status.component.scss']
})
export class DialogContentLoginStatusComponent implements OnInit {
  status: typeof LoginStatus = LoginStatus

  constructor(public dialogRef: MatDialogRef<DialogContentLoginStatusComponent>, @Inject(MAT_DIALOG_DATA) public data: LoginStatus) { }

  ngOnInit(): void {
  }

}
