import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateTrainingGuard } from '../core/guards/can-deactivate-training.guard';
import { SetupCategoriesComponent } from './components/setup-categories/setup-categories.component';
import { SetupExercisesComponent } from './components/setup-exercises/setup-exercises.component';
import { TrainingComponent } from './components/training/training.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { WarmupListComponent } from './components/warmup-list/warmup-list.component';
import { WarmupComponent } from './components/warmup/warmup.component';
import { HomePageComponent } from './home-page.component';



const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    
    children: [
      {
        path: 'warmups',
        component: WarmupListComponent
      },
      {
        path: 'warmup/:warmupId',
        component: WarmupComponent,
      },
      {
        path: 'warmup',
        component: WarmupComponent,
      },
      {
        path: 'trainings',
        component: TrainingsComponent
      },
      {
        path: 'training/:trainingId',
        component: TrainingComponent,
        canDeactivate: [CanDeactivateTrainingGuard]
      },
      {
        path: 'categories',
        component: SetupCategoriesComponent
      },
      {
        path: 'exercises',
        component: SetupExercisesComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule { }
