


<div class="container" fxLayout="row" fxLayoutAlign="center">


    
  

  <mat-card  class="example-card" >
    <mat-card-header>
      <mat-card-title>Exercises</mat-card-title>
      <mat-card-subtitle>Details</mat-card-subtitle>
      <button mat-icon-button aria-label="Refresh" (click)="refresh()">
              
        <mat-icon mat-list-icon>refresh</mat-icon>
        </button>
        
    </mat-card-header>
    
    <mat-card-content>

  
      <div *ngIf="isLoading" class="container">
        <app-preloader></app-preloader>
      </div>

  
      <app-custom-expandable-table *ngIf="!isLoading"
      [columnsToDisplay] = "columns"
      [tableData] = "exercises"></app-custom-expandable-table>


</mat-card-content>
</mat-card>

  

  
</div>

