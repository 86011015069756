import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { LocalizedString } from '@angular/compiler';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { TRAINING_DAY_DROP_PREFIX } from 'src/app/core/constants/globals';
import { Entry } from 'src/app/core/enums/entry.enum';
import { TrainingWeekDay } from 'src/app/core/enums/training-week-day.enum';
import { WarmupDataGetRequest } from 'src/app/core/models/classes/warmup-data-get-request';
import { Exercise } from 'src/app/core/models/interfaces/exercise';
import { Intensity } from 'src/app/core/models/interfaces/intensity';
import { SeriesDelay } from 'src/app/core/models/interfaces/series-delay';
import { Tempo } from 'src/app/core/models/interfaces/tempo';
import { TrainingDay } from 'src/app/core/models/interfaces/training-day';
import { TrainingExercise } from 'src/app/core/models/interfaces/training-exercise';
import { Warmup } from 'src/app/core/models/interfaces/warmup';
import { WarmupDay } from 'src/app/core/models/interfaces/warmup-day';
import { ExercisesService } from 'src/app/core/services/exercises.service';
import { IntensityService } from 'src/app/core/services/intensity.service';
import { SeriesDelayService } from 'src/app/core/services/series-delay.service';
import { TempoService } from 'src/app/core/services/tempo.service';
import { WarmupsService } from 'src/app/core/services/warmups.service';

@Component({
  selector: 'app-training-day-draft',
  templateUrl: './training-day-draft.component.html',
  styleUrls: ['./training-day-draft.component.scss']
})
export class TrainingDayDraftComponent implements OnInit, OnChanges {
  // public menuMode = MenuMode;
  // *ngIf="mode === MenuMode.TRAINING">
  public Entry = Entry;

  @Input() entry: Entry | undefined;
  dragShield: boolean = false;
  @Input() day?: TrainingWeekDay;
  @Input() trainingDay?: TrainingDay;
  @Output() trainingDayChange = new EventEmitter<TrainingDay>();

  basket: Exercise[] = [];
  test2: TrainingExercise[] = [];
  basket2: Exercise[] = [];
  warmup: TrainingExercise[] = [];

  
  intensity: Intensity[] = [];
  tempos: Tempo[] = [];
  series_delay: SeriesDelay[] = [];
  warmups: Warmup[] = [];

  

  get trainingDayDropPrefix() {
    return TRAINING_DAY_DROP_PREFIX;
  }

  constructor(private exercisesService: ExercisesService,
    private intensityService: IntensityService,
    private tempoService: TempoService,
    private seriesDelayService: SeriesDelayService,
    private warmupsService: WarmupsService) { }

  ngOnInit(): void {
    const intensity =  this.intensityService.getIntensityList();
    const tempo = this.tempoService.getTempoList();
    const delay = this.seriesDelayService.getSeriesDelay();
    const user = JSON.parse(localStorage.getItem('user')!);
    const warmupDataGet = new WarmupDataGetRequest(user['id']);
    const warmups = this.warmupsService.getTrainerWarmups(warmupDataGet);

    forkJoin([intensity, tempo, delay, warmups]).pipe()
    .subscribe(result => {
      this.intensity = result[0].intensivity_list;
      this.tempos = result[1].tempo_list;
      this.series_delay = result[2].series_delay;
      this.warmups = result[3].warmup_data;
    });

  }

  ngOnChanges() {
    this.rebuildDraftFromTrainingDayData();
  }

  rebuildDraftFromTrainingDayData() {
    this.exercisesService.getAllExercises().subscribe(data => {
      this.basket = [];
      this.test2 = [];
      if (this.trainingDay && this.trainingDay.training && this.trainingDay.training.length > 0) {
        this.test2 = this.trainingDay.training;
        this.test2.forEach(trainingExercise => {
          const exercise = data.exercises.find(e => e.ID === trainingExercise.exerciseId);
          this.basket.push(exercise as Exercise);
        });
      }
      this.basket2 = [];
      this.warmup = [];
      if (this.trainingDay && this.trainingDay.warmup && this.trainingDay.warmup.length > 0) {
        this.warmup = this.trainingDay.warmup;
        this.warmup.forEach(trainingExercise => {
          const exercise = data.exercises.find(e => e.ID === trainingExercise.exerciseId);
          this.basket2.push(exercise as Exercise);
        });
      }
    });
  }

  drop(event: CdkDragDrop<Exercise[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      [this.test2[event.previousIndex], this.test2[event.currentIndex]] = [this.test2[event.currentIndex], this.test2[event.previousIndex]];
      this.changeTrainingDayData();
    }
    if (event.previousContainer !== event.container) {
      copyArrayItem(event.previousContainer.data, event.container.data,  event.previousIndex, event.currentIndex);
      this.test2.splice(event.currentIndex, 0, {
        exerciseId: event.container.data[event.currentIndex].ID,
        tempo : "2-0-1-0",
        series_delay: "30",
        series: [{ repeats: "10", minutes: undefined, intensivity_scale: 1}]
      });
      this.changeTrainingDayData();
    }
    // this.setContentChanged(true);
   }

   changeTrainingDayData() {
     const newDayData: TrainingDay = {
       warmup: this.warmup,
       //?????????????????
       training: this.test2
     }
     this.trainingDayChange.emit(newDayData);
   }

   remove(i: any) {
    this.test2.splice(i, 1);
    this.basket.splice(i, 1);
    // this.setContentChanged(true);
   }

   removeWrm(i: any) {
    this.warmup.splice(i, 1);
    this.basket2.splice(i, 1);
    // this.setContentChanged(true);
   }

   addSeries(ind: any) {
    this.test2[ind].series.push({repeats: "10", minutes: undefined, intensivity_scale: 1});
    // this.setContentChanged(true);
   }

   addSeriesWrm(ind: any) {
    this.warmup[ind].series.push({repeats: "10", minutes: undefined, intensivity_scale: 1});
    // this.setContentChanged(true);
   }

   removeSeries(indexTrainingExercise: number, indexSeries: number) {
    this.test2[indexTrainingExercise].series.splice(indexSeries, 1);
    // this.setContentChanged(true);
   }

   removeSeriesWrm(indexTrainingExercise: number, indexSeries: number) {
    this.warmup[indexTrainingExercise].series.splice(indexSeries, 1);
    // this.setContentChanged(true);
   }

   getRepeatsForExercise(ind: number) {
    return this.test2[ind].series;
  }
  
  getRepeatsForExerciseWrm(ind: number) {
    return this.warmup[ind].series;
  }

  getIntensityMaxRange() {
    return this.intensity.length;
  }

  getIntensityIndex(intensivity_scale: number) {
    return this.intensity.filter(e => e.INTENSIVITY_SCALE == intensivity_scale).map(e => e.ID)[0];
  }

  getIntensityScale(event : any) {
    let index = event.value
    let intensityScale = 0;
    if (index) {
      intensityScale = this.intensity.filter(e => e.ID == index).map(e => e.INTENSIVITY_SCALE)[0];
    }
    return intensityScale;
  }

  getIntensityShortDescription(intensityScale: number) {
    return this.intensity.filter(e => e.INTENSIVITY_SCALE == intensityScale).map(e => e.INTENSIVITY_SHORT_DESCRIPTION)[0];
  }

  getWarmupFromData(warmupData: string) {
    const decodedData = atob(warmupData);
    const draft = JSON.parse(decodedData) as WarmupDay;
    return draft.warmup;
  }

  updateWarmupBin(warmup: TrainingExercise[]) {
   this.exercisesService.getAllExercises().subscribe(data => {
     warmup.forEach(trainingExercise => {
       const exercise = data.exercises.find(e => e.ID === trainingExercise.exerciseId);
       this.basket2.push(exercise as Exercise);
     });
     this.changeTrainingDayData();
   });
  }

}
