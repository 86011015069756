<div *ngIf="trainingStatus" fxFlexFill>
  <mat-chip-list fxFlex fxLayout="row">
    <mat-chip *ngIf="isTrainingStatusNew()" selected color="warn"
      [matTooltipShowDelay]="500" matTooltip="Training has not yet been modified.">
        New
    </mat-chip>
    <mat-chip *ngIf="isTrainingStatusDraft()" selected color="primary"
      [matTooltipShowDelay]="500" matTooltip="Training has been modified, but not completed yet.">
        Draft
    </mat-chip>
    <mat-chip *ngIf="isTrainingStatusCompleted()" selected color="accent"
      [matTooltipShowDelay]="500" matTooltip="Training is completed.">
        Completed
    </mat-chip>
  </mat-chip-list>
</div>
