import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { TrainingDataGetRequest } from '../models/classes/training-data-get-request';
import { TrainingDataSetRequest } from '../models/classes/training-data-set-request';
import { TrainingStatusSetRequest } from '../models/classes/training-status-set-request';
import { TrainingDataGetResponse } from '../models/interfaces/training-data-get-response';
import { TrainingDataSetResponse } from '../models/interfaces/training-data-set-response';
import { TrainingStatusSetResponse } from '../models/interfaces/training-status-set-response';
import { TrainingsResponse } from '../models/interfaces/trainings-response';

@Injectable({
  providedIn: 'root'
})
export class TrainingsService {

  constructor(private http: HttpClient) {
  }
  
  getAllTrainings() {
    return this.http.post<TrainingsResponse>(API.USER.TRAINER.TRAININGS, null);
  }

  getTrainingData(trainingDataGet: TrainingDataGetRequest) {
    return this.http.post<TrainingDataGetResponse>(API.USER.TRAINER.GET_TRAINING_DATA, trainingDataGet);
  }

  setTrainingData(trainingDataSet: TrainingDataSetRequest) {
    return this.http.post<TrainingDataSetResponse>(API.USER.TRAINER.SET_TRAINING_DATA, trainingDataSet);
  }

  setTrainingStatus(trainingStatusSet: TrainingStatusSetRequest) {
    return this.http.post<TrainingStatusSetResponse>(API.USER.TRAINER.SET_TRAINING_STATUS, trainingStatusSet);
  }
}
