<mat-card class="cont mat-elevation-z8" fxFlex>
  <mat-card-content>
    <div fxLayout="column">
      
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field class="example-form" appearance="fill">
        <mat-label>Warmup name</mat-label>
        <input matInput [formControl]="warmupName" required>
        <mat-error *ngIf="warmupName.invalid">You must enter a value</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-tab-group class="header-less-tabs">
        <mat-tab>
          <app-training-day-draft fxFlex fxFlexFill
            [entry]='entry.WARMUP'
            [day]="day"
            [(trainingDay)]="warmupDay">
          </app-training-day-draft>
        </mat-tab>
      </mat-tab-group>
    </div>

    </div>
  </mat-card-content>
</mat-card>
