import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AccountService } from '../core/services/account.service';
import { LoginStatus } from '../core/enums/login-status.enum';
import { DialogContentLoginStatusComponent } from './components/dialog-content-login-status/dialog-content-login-status.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  isLoggingIn: boolean = false;
  hide: boolean = true;

  login = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder, private accountService: AccountService,  private route: ActivatedRoute,    private router: Router, private dialog: MatDialog) {
    this.accountService.loginStatus.subscribe(status => {
      this.handleLoginStatus(status);
    });
  }

  ngOnInit(): void {
  }
  
  getErrorMessage() {
    if (this.login.controls.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.login.controls.email.hasError('email') ? 'Not a valid email' : '';
  }

  onSubmit() {
    if (this.login.valid) {
      this.isLoggingIn = true;
      const email = this.login.get('email')?.value;
      const password = this.login.get('password')?.value;
      this.accountService.login(email, password);
    }
  }

  handleLoginStatus(status: LoginStatus) {
    switch (status) {
      case LoginStatus.LOGGED_IN:
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
        this.isLoggingIn = false;
        break;
      case LoginStatus.BAD_USER_TYPE:
        const dialogRefUserType = this.dialog.open(DialogContentLoginStatusComponent, { data: status });
        dialogRefUserType.afterClosed().pipe(take(1)).subscribe(result => {
          //console.log(`Dialog result: ${result}`);
          this.isLoggingIn = false;
        });
        break;
      case LoginStatus.BAD_USER_OR_PASSWORD:
        const dialogRefUserOrPassword = this.dialog.open(DialogContentLoginStatusComponent, { data: status });
        dialogRefUserOrPassword.afterClosed().pipe(take(1)).subscribe(result => {
          //console.log(`Dialog result: ${result}`);
          this.isLoggingIn = false;
        });
        break;
      case LoginStatus.LOGGED_OUT:
      default:
        break;
    }
  }
}
