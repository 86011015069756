<h2 mat-dialog-title> 
  <span>Confirm action</span>
</h2>
<mat-divider></mat-divider>
<br>
<mat-dialog-content class="mat-typography">
  <p *ngIf="isClear()">
    Clear training and start from scratch.
  </p>
  <p *ngIf="isRestore()">
    Restore training to last save.
  </p>
  <p *ngIf="isSave()">
    Save training changes.
  </p>
  <p *ngIf="isDone()">
    Complete training, this action is irreversible.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onNoClick()" color="warn">Cancel</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>{{ action }}</button>
</mat-dialog-actions>
