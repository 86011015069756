import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-custom-expandable-table',
  templateUrl: './custom-expandable-table.component.html',
  styleUrls: ['./custom-expandable-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CustomExpandableTableComponent implements OnInit, OnChanges, AfterViewInit {
  //columnsToDisplay = ['ID', 'EXERCISE_NAME', 'EXERCISE_CATEGORY_ID'];
  expandedElement: any;
  dataSource = new MatTableDataSource(undefined);

  @Input() columnsToDisplay: any[] = [];
  @Input() tableData: any = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    console.log(this.tableData);
   this.dataSource.paginator = this.paginator;
   this.dataSource.sort = this.sort;
  }
 
  //drop(event: CdkDragDrop<string[]>) {
   // moveItemInArray(this.columnsToDisplay, event.previousIndex, event.currentIndex);
 // }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  DDD(data: string) {
    return atob(data);
  }

}
