import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { Entry } from 'src/app/core/enums/entry.enum';
import { TrainingWeekDay } from 'src/app/core/enums/training-week-day.enum';
import { TrainingDay } from 'src/app/core/models/interfaces/training-day';
import { TrainingDraft } from 'src/app/core/models/interfaces/training-draft';
import { TrainingDataService } from 'src/app/core/services/training-data.service';

@Component({
  selector: 'app-training-draft',
  templateUrl: './training-draft.component.html',
  styleUrls: ['./training-draft.component.scss']
})
export class TrainingDraftComponent implements OnInit {
  public entry = Entry;
  trainingWeekDays: TrainingWeekDay[] = [
    TrainingWeekDay.A,
    TrainingWeekDay.B,
    TrainingWeekDay.C,
    TrainingWeekDay.D,
    TrainingWeekDay.E,
    TrainingWeekDay.F,
    TrainingWeekDay.G
  ];
  tabItems: { trainingDay?: TrainingDay }[] = [];

  recentlySwappedPrev = -1;
  recentlySwappedCurr = -1;
  swapIndicatorsTimeout!: NodeJS.Timeout;
  selectedTab: number = 0;

  constructor(private trainingDataService: TrainingDataService) { }

  ngOnInit(): void {
    this.tabItems.push({}, {});
    this.trainingDataService.changeNumberOfTrainingDays(this.tabItems.length-1);
    this.trainingDataService.onClearDraftData.subscribe(clear => {
      if (clear) {
        this.tabItems = [];
        this.tabItems.push({}, {});
        this.trainingDataService.changeNumberOfTrainingDays(this.tabItems.length-1);
      }
    });
    this.trainingDataService.trainingData.subscribe(data => {
      if (data.TRAINING_DATA) {
        const decodedData = atob(data.TRAINING_DATA);
        const draft = JSON.parse(decodedData) as TrainingDraft;

        this.tabItems = [];
        if (draft.training_data.A as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.A as TrainingDay });
        }
        if (draft.training_data.B as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.B as TrainingDay });
        }
        if (draft.training_data.C as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.C as TrainingDay });
        }
        if (draft.training_data.D as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.D as TrainingDay });
        }
        if (draft.training_data.E as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.E as TrainingDay });
        }
        if (draft.training_data.F as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.F as TrainingDay });
        }
        if (draft.training_data.G as TrainingDay) {
          this.tabItems.push({ trainingDay: draft.training_data.G as TrainingDay });
        }
        this.tabItems.push({});
      }
    });
  }

  addTab(event: { stopPropagation: () => void; }) {
    event.stopPropagation();
    this.tabItems.push({});
    this.trainingDataService.changeNumberOfTrainingDays(this.tabItems.length-1);

    // this
  }

  removeTab(event: { stopPropagation: () => void; }, index: number) {
    let newSelected = this.selectedTab ;
    if (index < this.selectedTab ||
      this.selectedTab === this.tabItems.length -2) {
      newSelected = this.selectedTab -1;
    }

    event.stopPropagation();
    setTimeout(() => {
      this.tabItems.splice(index, 1);
      this.selectedTab = newSelected;
      this.trainingDataService.changeNumberOfTrainingDays(this.tabItems.length-1);
    }, 100);
  }
  
  drop(event: CdkDragDrop<{ trainingDay?: TrainingDay }[]>) {
    if (event.currentIndex != event.previousIndex) {
      clearTimeout(this.swapIndicatorsTimeout);
      this.recentlySwappedCurr = event.currentIndex;
      this.recentlySwappedPrev = event.previousIndex;
    }
    if (this.selectedTab === event.previousIndex) {
      this.selectedTab = event.currentIndex;
    } 
    else {
      if (event.currentIndex <= this.selectedTab &&
        event.previousIndex > this.selectedTab &&
        this.selectedTab < this.tabItems.length-2) {
        this.selectedTab = this.selectedTab + 1;
      } 
      if (event.currentIndex >= this.selectedTab &&
        event.previousIndex < this.selectedTab &&
        this.selectedTab > 0) {
        this.selectedTab = this.selectedTab - 1;
      }
    }

    if (event.currentIndex != event.previousIndex) {
      [this.tabItems[event.previousIndex].trainingDay, this.tabItems[event.currentIndex].trainingDay] =
      [this.tabItems[event.currentIndex].trainingDay, this.tabItems[event.previousIndex].trainingDay];

      this.swapIndicatorsTimeout = setTimeout(() => {
        this.recentlySwappedCurr = -1;
        this.recentlySwappedPrev = -1;
      }, 1000);
    }
  }

  sortPredicate(index: number, item: CdkDrag<number>, drop: CdkDropList) {
     return index + 1 != drop.data.length;
  }

}
