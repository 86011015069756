import { Component, OnInit } from '@angular/core';
import { AccountService } from '../core/services/account.service';
import { TrainingsService } from '../core/services/trainings.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  isExpanded = true;
  isSetupHidden = true;

  constructor(private accountService: AccountService, private trainingsService: TrainingsService) { }

  ngOnInit(): void {
  }
  
  test() {
    this.trainingsService.getAllTrainings().subscribe(response => {
      console.log(response);
    });
  }

  logout() {
    this.accountService.logout();
  }

}
