<div fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">
  <div fxFlex fxFlexFill fxLayout="column" fxlayoutAlign="start stretch">
    <div fxFlex="none" fxLayout="row" fxlayoutAlign="start stretch">
      <app-training-menu fxFlex
       [entry]='entry.WARMUP'
       (menuActionEvent)="handleMenuAction($event)">
      </app-training-menu>
    </div>
    <div fxFlex fxFlexFill fxLayout="row" class="scrollable">
      <div [ngClass]="{'show': showSidebar, 'hide' : !showSidebar }"
        [style.max-width]="sidebarSize"
         fxLayout="column" fxlayoutAlign="start stretch" class="scrollable"> 
        <app-exercises fxFlex>
        </app-exercises>
      </div>
      <div class="margin" >
          <button mat-stroked-button class="toggbtn" fxFlex="0.8em" fxFlexFill (click)="toggleSidebar()">
            <div fxFlexFill fxLayout="column" fxLayoutAlign="space-around center">
              <mat-icon *ngIf="showSidebar">arrow_left</mat-icon>
              <mat-icon *ngIf="!showSidebar">arrow_right</mat-icon>
              <mat-icon cdkDragHandle>more_vert</mat-icon>
              <mat-icon *ngIf="showSidebar">arrow_left</mat-icon>
              <mat-icon *ngIf="!showSidebar">arrow_right</mat-icon>
            </div>
          </button>
      </div>
      <div fxFlex fxLayout="column" fxlayoutAlign="start stretch" class="scrollable">
        <app-warmup-draft fxFlex>
        </app-warmup-draft>
      </div>
    </div>
  </div>
</div>
