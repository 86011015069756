import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-swap-spinner',
  templateUrl: './tab-swap-spinner.component.html',
  styleUrls: ['./tab-swap-spinner.component.scss']
})
export class TabSwapSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
