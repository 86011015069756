<div fxFlex fxFlexFill fxLayout="row" fxLayoutAlign="center center">
  <mat-form-field appearance="fill" class="w120">
    <mat-label>Type</mat-label>
    <mat-select [(value)]="selected" (selectionChange)="updateType($event.value)">
      <mat-option *ngFor="let repeatType of repeatTypes" [value]="repeatType">
        {{ repeatType }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="repeatTypes[2]!==selected" fxFlex fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    &nbsp;:&nbsp;
    <input *ngIf="repeatTypes[0]===selected" type="text" size="5" [(ngModel)]="repeats" (change)="onRepeatsChange()">
    <input *ngIf="repeatTypes[1]===selected" type="text" size="5" [(ngModel)]="minutes" (change)="onMinutesChange()">
  </div>
</div>


