
<div class="container">

  <mat-toolbar color="primary">
    <!-- <button mat-icon-button aria-label="Menu" (click)="sidenav.toggle()"> -->
      <button mat-icon-button aria-label="Menu" (click)="isExpanded = !isExpanded">
      <mat-icon class="example-icon">menu</mat-icon>
    </button>
    <h1 class="example-app-name">
      <span>FITWORKOUT.pl Trainer</span>
    </h1>

    <mat-icon class="example-icon">fitness_center</mat-icon>
    

    <span class="example-spacer"></span>
    <button mat-icon-button aria-label="Language">
      <mat-icon class="example-icon">language</mat-icon>
    </button>
    <button mat-icon-button aria-label="Account" [satPopoverAnchor]="popoverAccountDetails" (click)="popoverAccountDetails.toggle()">
      <mat-icon class="example-icon">account_circle</mat-icon>
    </button>
    <button mat-icon-button aria-label="Logout" (click)="logout()">
      <mat-icon class="example-icon">logout</mat-icon>
    </button>
  </mat-toolbar>

  <sat-popover #popoverAccountDetails [hasBackdrop]=true (backdropClicked)="popoverAccountDetails.close()" verticalAlign="below" horizontalAlign="end" [autoFocus]="false">
    <div class="info-wrapper">
      <app-account-details></app-account-details>
    </div>
  </sat-popover>

  <mat-sidenav-container autosize >
    <mat-sidenav #sidenav mode="side" opened="true">
  
      <mat-nav-list>
        <mat-list-item routerLink="trainings">
         <mat-icon mat-list-icon>home</mat-icon>
           <p matLine *ngIf="isExpanded">Home</p>
        </mat-list-item>
        <mat-list-item routerLink="warmups">
         <mat-icon mat-list-icon>directions_run</mat-icon>
           <p matLine *ngIf="isExpanded">Warmups</p>
        </mat-list-item>
        <mat-list-item routerLink="trainings">
         <mat-icon mat-list-icon>sports</mat-icon>
           <p matLine *ngIf="isExpanded">Trainings</p>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item fxLayoutAlign="center">
          <button mat-icon-button aria-label="Setup" (click)="isSetupHidden = !isSetupHidden" fxLayout="row" fxLayoutAlign="center center">
            
          <mat-icon mat-list-icon>settings</mat-icon>
          <mat-icon mat-list-icon *ngIf="isSetupHidden">expand_more</mat-icon>
          <mat-icon mat-list-icon *ngIf="!isSetupHidden">expand_less</mat-icon>
          </button>
         </mat-list-item>

         <mat-list-item *ngIf="!isSetupHidden" routerLink="categories">
          <mat-icon mat-list-icon>format_list_numbered</mat-icon>
          <p matLine *ngIf="isExpanded">Categories</p>
         </mat-list-item>
         <mat-list-item *ngIf="!isSetupHidden" routerLink="exercises">
          <mat-icon mat-list-icon>fitness_center</mat-icon>
          <p matLine *ngIf="isExpanded">Exercises</p>
         </mat-list-item>
         
      </mat-nav-list>
    </mat-sidenav>
  
    <mat-sidenav-content>
      <div class="sidenav-content-container">
  
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  
  </mat-sidenav-container>
  
</div>
