

<div class="container" fxLayout="row" fxLayoutAlign="center">


  <div *ngIf="isLoading" fxLayoutAlign="center center">
    <app-preloader></app-preloader>
  </div>

      
  

<div *ngIf="!isLoading" class="container">

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Warmups</mat-card-title>
      <mat-card-subtitle>Trainer warmups</mat-card-subtitle>
      <button mat-icon-button aria-label="Refresh" (click)="refresh()">
              
        <mat-icon mat-list-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button aria-label="Add" (click)="add()">
                
          <mat-icon mat-list-icon>add</mat-icon>
          </button>
    </mat-card-header>
    
    <mat-card-content>

  <table mat-table
  [dataSource]="warmups"
  class="mat-elevation-z8">





  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
  <th mat-header-cell *matHeaderCellDef> {{column}} </th>
  <td mat-cell *matCellDef="let element">
    
    {{element[column]}} 
    <span *ngIf="'VIEW'===column"><button mat-button (click)="openTraining(element)">Edit</button></span>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>


  

</mat-card-content>
</mat-card>
</div>
  

  
  
  
</div>

