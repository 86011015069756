
<div class="table-wrapper" fxFlex>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
  </mat-form-field>
  

  <div class="example-container mat-elevation-z8" fxLayout="column">

    <table mat-table [dataSource]="dataSource" matSort>
  
  
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell cdkDrag *matHeaderCellDef mat-sort-header> {{ column }} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>
  
      
  
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
    
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>