import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/classes/user';
import { AccountService } from 'src/app/core/services/account.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  user!: User;

  constructor(private accountService: AccountService) {
    this.accountService.user.subscribe(user => {
      this.user = user
    });
  }

  ngOnInit(): void {
  }

}
