import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { CustomExpandableTableComponent } from './components/custom-expandable-table/custom-expandable-table.component';
import { TabSwapSpinnerComponent } from './components/tab-swap-spinner/tab-swap-spinner.component';



@NgModule({
  declarations: [
    PreloaderComponent,
    CustomExpandableTableComponent,
    TabSwapSpinnerComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SatPopoverModule
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SatPopoverModule,
    PreloaderComponent,
    CustomExpandableTableComponent,
    TabSwapSpinnerComponent
  ]
})
export class SharedModule { }
