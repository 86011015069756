import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-repeat-type',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.scss']
})
export class RepeatTypeComponent implements OnInit {
  @Output() repeatsChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() repeats: string | undefined;
  @Output() minutesChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() minutes: string | undefined;

  repeatTypes: string[] = [
    'Repeats',
    'Minutes',
    'X',
  ];

  selected = "";

  constructor() { }

  ngOnInit(): void {
    if (this.repeats) {
      this.selected = this.repeatTypes[0]
    } else if (this.minutes) {
      this.selected = this.repeatTypes[1]
    } else {
      this.selected = this.repeatTypes[2]
    }
  }

  onRepeatsChange() {
    this.repeatsChange.emit(this.repeats);
  } 

  onMinutesChange() {
    this.minutesChange.emit(this.minutes);
  } 

  updateType(type: string) {
    switch(type) { 
      case 'Repeats': {
        this.minutesChange.emit(undefined);
         break; 
      } 
      case 'Minutes': {
        this.repeatsChange.emit(undefined);
         break; 
      } 
      case 'X': 
      default: {
        this.minutesChange.emit(undefined);
        this.repeatsChange.emit(undefined);
         break; 
      } 
    }
  }

}
