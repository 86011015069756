<mat-card class="cont mat-elevation-z8" fxFlex>
  <mat-card-content>
    <div fxLayout="column">
      <div 
        fxFlex fxLayout="row"
        class="example-list"
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListSortPredicate]="sortPredicate"
        [cdkDropListData]="tabItems">
        <ng-container *ngFor="let tabItem of tabItems; let index = index; let last = last">
          <div *ngIf="index<trainingWeekDays.length" 
            class="example-box"  
            cdkDrag
            cdkDragLockAxis="x"
            [cdkDragDisabled]="last">
            <div
              matRipple
              fxFlex fxFlexFill
              fxLayout="column"
              fxLayoutAlign="center center"
              (click)="!last && selectedTab=index">
              <div *ngIf="!last" fxFlexFill>
                <app-tab-swap-spinner *ngIf="recentlySwappedPrev === index || recentlySwappedCurr === index"></app-tab-swap-spinner>
                <div fxFlex fxLayoutAlign="center center">
                  {{ trainingWeekDays[index] }}
                </div>
                <div fxLayoutAlign="center center" [ngClass]="{'disabled': tabItems.length === 2}">
                  <mat-icon (click)="tabItems.length > 2 && removeTab($event, index)">
                    clear
                  </mat-icon>
                </div>
              </div>
              <div *ngIf="!last" [ngClass]="{'selected': selectedTab === index}">
              </div>
              <div *ngIf="last"
                fxFlex fxFlexFill
                fxLayoutAlign="center center"
                (click)="addTab($event)">
                <mat-icon>
                  add
                </mat-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      
      <mat-tab-group [selectedIndex]="selectedTab" class="cont1 header-less-tabs">
        <mat-tab *ngFor="let tabItem of tabItems; let index=index">

          <app-training-day-draft fxFlex fxFlexFill
          [entry]='entry.TRAINING'
          [day]="trainingWeekDays[index]"
          [(trainingDay)]="tabItem.trainingDay">
        </app-training-day-draft>

        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card-content>
</mat-card>
