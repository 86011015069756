import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { Error } from 'src/app/core/enums/error.enum';
import { MenuAction } from 'src/app/core/enums/menu-action.enum';
import { Entry } from 'src/app/core/enums/entry.enum';
import { ResponseStatus } from 'src/app/core/enums/response-status.enum';
import { TrainingStatus } from 'src/app/core/enums/training-status.enum';
import { TrainingDataGetRequest } from 'src/app/core/models/classes/training-data-get-request';
import { TrainingDataSetRequest } from 'src/app/core/models/classes/training-data-set-request';
import { TrainingStatusSetRequest } from 'src/app/core/models/classes/training-status-set-request';
import { WarmupDataGetRequest } from 'src/app/core/models/classes/warmup-data-get-request';
import { Intensity } from 'src/app/core/models/interfaces/intensity';
import { SeriesDelay } from 'src/app/core/models/interfaces/series-delay';
import { Tempo } from 'src/app/core/models/interfaces/tempo';
import { TrainingData } from 'src/app/core/models/interfaces/training-data';
import { TrainingDay } from 'src/app/core/models/interfaces/training-day';
import { TrainingDraft } from 'src/app/core/models/interfaces/training-draft';
import { Warmup } from 'src/app/core/models/interfaces/warmup';
import { IntensityService } from 'src/app/core/services/intensity.service';
import { SeriesDelayService } from 'src/app/core/services/series-delay.service';
import { TempoService } from 'src/app/core/services/tempo.service';
import { TrainingDataService } from 'src/app/core/services/training-data.service';
import { TrainingStateService } from 'src/app/core/services/training-state.service';
import { TrainingsService } from 'src/app/core/services/trainings.service';
import { WarmupsService } from 'src/app/core/services/warmups.service';
import { DialogContentConfirmActionComponent } from '../dialog-content-confirm-action/dialog-content-confirm-action.component';
import { DialogContentConfirmLeavingPageComponent } from '../dialog-content-confirm-leaving-page/dialog-content-confirm-leaving-page.component';
import { TrainingDraftComponent } from '../training-draft/training-draft.component';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit, OnDestroy {
  private trainingId: string | null;
  public entry = Entry;
  private trainingData?: TrainingData;
  private contentChanged: boolean = false;
  //isLoading: boolean = true;

  @ViewChild(TrainingDraftComponent) trainingDraftC?: TrainingDraftComponent;

  showSidebar: boolean = true;
  sidebarSize: string = '30%';

  intensity: Intensity[] = [];
  tempos: Tempo[] = [];
  series_delay: SeriesDelay[] = [];
  warmups: Warmup[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private intensityService: IntensityService,
    private tempoService: TempoService,
    private seriesDelayService: SeriesDelayService,
    private trainingsService: TrainingsService,
    private trainingDataService: TrainingDataService,
    private trainingStateService: TrainingStateService,
    private warmupsService: WarmupsService) {
      this.trainingId = null;
    }

  ngOnInit(): void {
    this.trainingDataService.trainingData.subscribe(trainingData => {
      this.trainingData = trainingData;
    });
    this.trainingStateService.resetState();
    this.trainingStateService.closeEntry().subscribe(close => {
      if (close) {
        this.backToTrainings(); 
      }
    });
    this.getTrainingData();
  }

  ngOnDestroy() {
    this.trainingStateService.stopCountdown();
    this.trainingDataService.changeTrainingData({} as TrainingData);
  }

  getTrainingData() {
    this.trainingId = this.route.snapshot.paramMap.get('trainingId');
    if (this.trainingId) {
      const trainingDataGetRequest = new TrainingDataGetRequest(this.trainingId);
      this.trainingsService.getTrainingData(trainingDataGetRequest).pipe(
        map(data => {
          if (ResponseStatus.SUCCESS == data.response_status) {
            this.trainingDataService.changeTrainingData(data.training_data);
            //console.log(data.training_data);
          }
          return data.response_status;
        }),
        mergeMap(status => {
          if (ResponseStatus.SUCCESS == status) {
            const intensity =  this.intensityService.getIntensityList();
            const tempo = this.tempoService.getTempoList();
            const delay = this.seriesDelayService.getSeriesDelay();
            const user = JSON.parse(localStorage.getItem('user')!);
            const warmupDataGet = new WarmupDataGetRequest(user['id']);
            const warmups = this.warmupsService.getTrainerWarmups(warmupDataGet);
            return forkJoin([intensity, tempo, delay, warmups]);
          } else {
            throw Error.NO_TRAINING_DATA;
          }
        })
      ).subscribe(result => {
        if (result) {
          result.forEach(e => {
            if (ResponseStatus.SUCCESS != e.response_status) {
              this.trainingStateService.changeLoading(false);
              //this.isLoading = false;
              this.snackBar.open(Error.NO_TRAINING_SETUPS, "Ok", { duration: 10 * 1000 });
              console.log(e.response_status, Error.NO_TRAINING_SETUPS);
              throw Error.NO_TRAINING_SETUPS;
            }
          });
          this.intensity = result[0].intensivity_list;
          this.tempos = result[1].tempo_list;
          this.series_delay = result[2].series_delay;
          this.warmups = result[3].warmup_data;
          //this.rebuildDraftFromData();
          
          this.trainingStateService.changeLoading(false);
          //this.isLoading = false;
        }
      }, error => {
        this.snackBar.open(error, "Ok", { duration: 10 * 1000 });
        console.log(error);
        throw error;
      });
    }
  }



  toggleSidebar() {
    if (this.showSidebar) {
      this.sidebarSize = "0%";
    } else {
      this.sidebarSize = "30%";
    }
    this.showSidebar = !this.showSidebar;
  }

  isContentChanged() {
    return this.contentChanged;
  }

  setContentChanged(contentChanged: boolean) {
    this.contentChanged = contentChanged;
  }

  openConfirmDialog(): Observable<boolean>{
    const dialogRef = this.dialog.open(DialogContentConfirmLeavingPageComponent);
    return dialogRef.afterClosed();
  }

  handleMenuAction(menuAction: MenuAction) {
    switch (menuAction) {
      case MenuAction.BACK:
        this.backToTrainings();
        break;
      case MenuAction.CLEAR:
        this.reset();
        break;
      case MenuAction.RESTORE:
        this.restore();
        break;
      case MenuAction.SAVE:
        this.save();
        break;
      case MenuAction.DONE:
        this.done();
        break;
    
      default:
        break;
    }
  }

  handleConfirmationDialog(action: MenuAction) {
    const confirmationDialogRef = this.dialog.open(DialogContentConfirmActionComponent, { data: action });
    return confirmationDialogRef.afterClosed().pipe(take(1));
  }

  backToTrainings() {
    this.router.navigate(["home/trainings"]);
  }

  reset() {
    this.handleConfirmationDialog(MenuAction.CLEAR).subscribe(result => {
      if (result) {
        this.trainingDataService.clearDraftData();
        this.snackBar.open("Training cleared", "Ok", { duration: 3 * 1000 });
      }
      this.setContentChanged(true);
    });
  }

  restore() {
    this.handleConfirmationDialog(MenuAction.RESTORE).subscribe(result => {
      if (result) {
        if (this.trainingId) {
          this.trainingStateService.changeLoading(true);
          const trainingDataGetRequest = new TrainingDataGetRequest(this.trainingId);
          this.trainingsService.getTrainingData(trainingDataGetRequest).pipe().subscribe(result => {
            if (ResponseStatus.SUCCESS != result.response_status) {
              this.trainingStateService.changeLoading(false);
              this.snackBar.open(Error.NO_TRAINING_DATA, "Ok", { duration: 10 * 1000 });
              console.log(result.response_status, Error.NO_TRAINING_DATA);
              throw Error.NO_TRAINING_DATA;
            }
            this.trainingDataService.changeTrainingData(result.training_data);
            //this.rebuildDraftFromData();

            
          // this.trainingDataService.clearData();

            this.trainingStateService.changeLoading(false);
            this.snackBar.open("Training restored", "Ok", { duration: 3 * 1000 });
            this.setContentChanged(true);
          }, error => {
            this.trainingStateService.changeLoading(false);
            this.snackBar.open(error, "Ok", { duration: 10 * 1000 });
            console.log(error);
            throw error;
          });
        } else {
          this.trainingStateService.changeLoading(false);
          console.log("Training id: "  + this.trainingId);
          throw Error.NO_TRAINING_ID;
        }
      }
    });
  }

  save() {
    this.handleConfirmationDialog(MenuAction.SAVE).subscribe(result => {
      if (result) {
        if (this.trainingData) {
          this.trainingStateService.changeSaving(true);

          const draft: TrainingDraft = {
            training_data: {
            }
          }
          var trainingDays: (TrainingDay | undefined)[];
          if (this.trainingDraftC) {
            trainingDays = this.trainingDraftC.tabItems.filter(e => e !== undefined && e.trainingDay !== undefined).flatMap(e => e.trainingDay);
            trainingDays?.forEach((trainingDay, index) => {
              switch (index) {
                case 0:
                  draft.training_data.A = trainingDay;
                  break;
                case 1:
                  draft.training_data.B = trainingDay;
                  break;
                case 2:
                  draft.training_data.C = trainingDay;
                  break;
                case 3:
                  draft.training_data.D = trainingDay;
                  break;
                case 4:
                  draft.training_data.E = trainingDay;
                  break;
                case 5:
                  draft.training_data.F = trainingDay;
                  break;
                case 6:
                  draft.training_data.G = trainingDay;
                  break;
              
                default:
                  break;
              }
            });
          }
    
          // console.log(JSON.stringify(draft));
          const encodedData = btoa(JSON.stringify(draft));
          const trainingDataSetRequest = new TrainingDataSetRequest(this.trainingData.CUSTOMER_ID, this.trainingData.ID, encodedData);
          const trainingStatusSetRequest = new TrainingStatusSetRequest(this.trainingData.CUSTOMER_ID, this.trainingData.ID, TrainingStatus.DRAFT);
          const trainingData = this.trainingsService.setTrainingData(trainingDataSetRequest);
          const trainingStatus = this.trainingsService.setTrainingStatus(trainingStatusSetRequest);
          forkJoin([trainingData, trainingStatus]).pipe(
            mergeMap(result => {
              let errorsLog = new Array();
              let errors = new Array(); 
              let logMessages = new Array("SetTrainingData response status: ", "SetTrainingStatus response status: "); 
              if (result) {
                if (ResponseStatus.SUCCESS != result[0].response_status) {
                  errorsLog.push(logMessages[0] + result[0].response_status);
                  errors.push(Error.SET_TRAINING_DATA_FAIL);
                }
                if (ResponseStatus.SUCCESS != result[1].response_status) {
                  errorsLog.push(logMessages[1] + result[1].response_status);
                  errors.push(Error.SET_TRAINING_STATUS_FAIL);
                }
                if (errors.length > 0) {
                  throw { errors, errorsLog };
                }
              }
              if (this.trainingId) {
                this.trainingStateService.changeSaving(false);
                this.trainingStateService.changeLoading(true);
                const trainingDataGetRequest = new TrainingDataGetRequest(this.trainingId);
                return this.trainingsService.getTrainingData(trainingDataGetRequest);
              } else {
                errorsLog.push("Training id: " + this.trainingId);
                errors.push(Error.NO_TRAINING_ID);
                throw { errors, errorsLog };
              }
            })
          )
          .subscribe(result => {
            if (result) {
              if (ResponseStatus.SUCCESS != result.response_status) {
                this.trainingStateService.changeLoading(false);
                this.snackBar.open(Error.NO_TRAINING_DATA, "Ok", { duration: 10 * 1000 });
                console.log(result.response_status, Error.NO_TRAINING_DATA);
                throw Error.NO_TRAINING_DATA;
              }
              this.trainingDataService.changeTrainingData(result.training_data);
              this.trainingStateService.changeLoading(false);
              this.snackBar.open("Training saved", "Ok", { duration: 3 * 1000 });
            }
          }, error => {
            this.trainingStateService.changeSaving(false);
            this.snackBar.open(error.errors.join(", "), "Ok", { duration: 10 * 1000 });
            console.log(error.errorsLog.join(", "), "|" , error.errors.join(", "));
            throw error;
          });
        }
      }
      this.setContentChanged(false);
    });
  }

  done() {
    this.handleConfirmationDialog(MenuAction.DONE).subscribe(result => {
      if (result) {
        if (this.trainingData) {
          this.trainingStateService.changeSaving(true);
          const trainingStatusSetRequest = new TrainingStatusSetRequest(this.trainingData.CUSTOMER_ID, this.trainingData.ID, TrainingStatus.COMPLETED);
          this.trainingsService.setTrainingStatus(trainingStatusSetRequest).pipe(
            map(response => {
              this.trainingStateService.changeSaving(false);
              if (ResponseStatus.SUCCESS == response.response_status) {
                return response.response_status;
              } else {
                throw Error.SET_TRAINING_STATUS_FAIL;
              }
            }),
            mergeMap(status => {
              if (ResponseStatus.SUCCESS == status && this.trainingId) {
                this.trainingStateService.changeLoading(true);
                const trainingDataGetRequest = new TrainingDataGetRequest(this.trainingId);
                return this.trainingsService.getTrainingData(trainingDataGetRequest);
              } else {
                throw Error.NO_TRAINING_ID;
              }
            })
          ).subscribe(result => {
            if (result) {
              if (ResponseStatus.SUCCESS != result.response_status) {
                this.trainingStateService.changeLoading(false);
                this.snackBar.open(Error.NO_TRAINING_DATA, "Ok", { duration: 10 * 1000 });
                console.log(result.response_status, Error.NO_TRAINING_DATA);
                throw Error.NO_TRAINING_DATA;
              }
              this.trainingDataService.changeTrainingData(result.training_data);
              this.trainingStateService.changeLoading(false);
              this.trainingStateService.changeClosing(true);
              this.snackBar.open("Training completed", "Ok", { duration: 3 * 1000 });
              this.trainingStateService.startCountdown();
            }
          }, error => {
            this.snackBar.open(error, "Ok", { duration: 10 * 1000 });
            console.log(error);
            throw error;
          });
        }
      }
    });
  }

}
