<div *ngIf="isLoading" fxFlex >
  <app-preloader fxFlex></app-preloader>
</div>
  <mat-card *ngIf="!isLoading" class="cont mat-elevation-z8">
    <mat-card-header>
      <mat-card-title>Exercises</mat-card-title>
      <div fxFlex></div>
      <div class="photo-video-filter" [formGroup]="filter"
        fxFlex="60" fxLayout="row" fxLayoutAlign="space-evenly center">
          <span>Show only with:</span>
          <mat-checkbox color="primary" formControlName="showOnlyPhotos">Photos</mat-checkbox>
          <mat-checkbox color="primary" formControlName="showOnlyVideos">Videos</mat-checkbox>
      </div>
    </mat-card-header>
    <mat-card-content>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let category of categories; let index = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>
            {{ category.EXERCISE_CATEGORY_NAME}}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <!-- 
        [id]="'exercises-drop-list-'+ index"
 -->
        <div class="example-list"
        [cdkDropListData]="getExercisesForCategory(category.ID)"
        cdkDropList
        

        [id]="exercisesDropPrefix + index"
        [cdkDropListConnectedTo]="trainingDayDropIds"
        cdkDropListSortingDisabled

        >

          <div class="example-box" *ngFor="let exercise of getExercisesForCategory(category.ID)" cdkDrag>
            <div  class="example-custom-placeholder" *cdkDragPlaceholder>
              {{ exercise.EXERCISE_NAME }}
            </div>
            <span>
              {{ exercise.EXERCISE_NAME }}
            </span>
            <div fxLayout="row">
              <mat-icon *ngIf="1 == exercise.EXERCISE_HAVE_PHOTO"
                [matTooltipShowDelay]="500"
                matTooltip="Exercise with photo(s)">
                photo_camera
              </mat-icon>
              <mat-icon *ngIf="1 == exercise.EXERCISE_HAVE_VIDEO"
                [matTooltipShowDelay]="500"
                matTooltip="Exercise with video(s)">
                videocam
              </mat-icon>
            </div>
          </div>
        </div>  
      </div>
    </mat-expansion-panel> 
  </mat-accordion>


</mat-card-content>
</mat-card>