import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-content-confirm-leaving-page',
  templateUrl: './dialog-content-confirm-leaving-page.component.html',
  styleUrls: ['./dialog-content-confirm-leaving-page.component.scss']
})
export class DialogContentConfirmLeavingPageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogContentConfirmLeavingPageComponent>) { }

  ngOnInit(): void {
  }

}
