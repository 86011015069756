export class User {
  private id: number;
  //CREATE_DATE: string,
  private type: number;
  name: string;
  surname: string;

  constructor(id: number, type: number, name: string, surname: string) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.surname = surname;
  }

  get Type() {
    return this.type;
  }
  
  get Id() {
    return this.id;
  }
}
