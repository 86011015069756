<div class="container" fxLayout fxLayoutAlign="center center">
  <mat-card>
    <mat-card-header> 
      <mat-card-title>
      <br>
        <div fxLayout="column" fxLayoutAlign="center center">
          <div>
            <img class="logoContainer"  mat-card-image src="assets/images/fitworkoutLogo.png" alt="Logo">
          </div>
          <div class="icon-container">
            <mat-icon class="icon-display">login</mat-icon>
          </div>
          <div>
            <span>Log In</span>
          </div>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="login">
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <mat-icon matPrefix>mail_outline</mat-icon>
          <input matInput placeholder="E-mail" formControlName="email" required> 
         <mat-error *ngIf="login.controls.email.invalid">{{getErrorMessage()}}</mat-error> 
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <mat-icon matPrefix>lock_outline</mat-icon>
          <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field> 
        <mat-card-actions>
          <div fxLayout="column" fxLayoutAlign="center center">
            <button *ngIf="!isLoggingIn" mat-raised-button color="primary" [disabled]="!login.valid" class="login-field" (click)="onSubmit()"> Login</button>
            <mat-spinner *ngIf="isLoggingIn" diameter="36"></mat-spinner> 
          </div>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
</div>
