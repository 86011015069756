import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainingStateService {
  private loading: BehaviorSubject<boolean>;
  private saving: BehaviorSubject<boolean>;
  private closing: BehaviorSubject<boolean>;
  private progress: BehaviorSubject<number>;
  private timeLeft: BehaviorSubject<number>;
  private closeCountdown: Subscription | undefined;
  private close: BehaviorSubject<boolean>;
  private progressTimer: number = 0;
  private timeLeftTimer: number = 10000;

  constructor() {
    this.loading = new BehaviorSubject<boolean>(true);
    this.saving = new BehaviorSubject<boolean>(false);
    this.closing = new BehaviorSubject<boolean>(false);
    this.progress = new BehaviorSubject<number>(0);
    this.timeLeft = new BehaviorSubject<number>(10000);
    this.close = new BehaviorSubject<boolean>(false);
  }

  public isLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }

  public changeLoading(value: boolean) {
    this.loading.next(value);
  }

  public isSaving(): Observable<boolean> {
    return this.saving.asObservable();
  }

  public changeSaving(value: boolean) {
    this.saving.next(value);
  }

  public isClosing(): Observable<boolean> {
    return this.closing.asObservable();
  }
  
  public changeClosing(value: boolean) {
    this.closing.next(value);
  }

  public closeProgress(): Observable<number> {
    return this.progress.asObservable();
  }

  public closeTimeLeft(): Observable<number> {
    return this.timeLeft.asObservable();
  }

  public startCountdown() {
    this.progressTimer = 0;
    this.timeLeftTimer = 10000;
    this.closeCountdown = interval(100).pipe(take(100)).subscribe(() => {
      this.onTimeOut();
    });
  }

  public stopCountdown() {
    if (this.closeCountdown) {
      this.closeCountdown.unsubscribe();
    }
  }

  private onTimeOut() {
    this.progress.next(this.progressTimer += 1);
    this.timeLeft.next(this.timeLeftTimer -= 100);
    if (0 == this.timeLeftTimer && 100 == this.progressTimer) {
      this.close.next(true);
    }
  }

  public closeEntry(): Observable<boolean> {
    return this.close.asObservable();
  }

  public resetState() {
    this.changeLoading(true);
    this.changeSaving(false);
    this.changeClosing(false);
    this.close.next(false);
  }
  
}
