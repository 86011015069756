import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Entry } from 'src/app/core/enums/entry.enum';
import { TrainingWeekDay } from 'src/app/core/enums/training-week-day.enum';
import { TrainingDay } from 'src/app/core/models/interfaces/training-day';
import { WarmupDay } from 'src/app/core/models/interfaces/warmup-day';
import { WarmupDataService } from 'src/app/core/services/warmup-data.service';

@Component({
  selector: 'app-warmup-draft',
  templateUrl: './warmup-draft.component.html',
  styleUrls: ['./warmup-draft.component.scss']
})
export class WarmupDraftComponent implements OnInit, OnDestroy {
  public entry = Entry;
  warmupName = new FormControl('', Validators.required);
  day = TrainingWeekDay.A;

  warmupDay!: TrainingDay;

  private destroy$ = new Subject();

  constructor(private warmupDataService: WarmupDataService) { }

  ngOnInit(): void {
    this.warmupDataService.onClearDraftData.subscribe(clear => {
      if (clear) {
        this.warmupDay = { warmup: [], training: [] };
      }
    });
    this.warmupDataService.warmupData.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.WARMUP_DATA) {
        this.warmupName.setValue(data.WARMUP_NAME);
        const decodedData = atob(data.WARMUP_DATA);
        const draft = JSON.parse(decodedData) as WarmupDay;
        this.warmupDay = { warmup: [], training: draft.warmup };
      }
    });
  
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
