export class TrainingDataSetRequest {
  private customerId: number;
  private trainingId: number;
  private trainingData: string; // base64

  constructor(customerId: number, trainingId: number, trainingData: string) {
    this.customerId = customerId;
    this.trainingId = trainingId;
    this.trainingData = trainingData;
  }
}
