import { environment } from "src/environments/environment";

const URL = (endpoint: string) => `${environment.WS}/${endpoint}`;

export const API = {
  USER: {
    AUTH: URL('user/getauthkey.php'),
    LOGIN: URL('user/login.php'),

    TRAINER: {
      TRAININGS: URL('user/TRAINER_gettrainingslist.php'),
      GET_TRAINING_DATA: URL('user/TRAINER_gettrainingdata.php'),
      SET_TRAINING_DATA: URL('user/TRAINER_settrainingdata.php'),
      SET_TRAINING_STATUS: URL('user/TRAINER_settrainingstatus.php'),
      CATEGORIES: URL('user/TRAINER_getexercisescategorieslist.php'),
      EXERCISES: URL('user/TRAINER_getexerciseslist.php'),
      INTENSITY_LIST: URL('user/TRAINER_getintensivitylist.php'),
      TEMPO_LIST: URL('user/TRAINER_gettempolist.php'),
      SERIES_DELAY: URL('user/TRAINER_getseriesdelay.php'),
      GET_TRAINER_WARMUPS: URL('user/TRAINER_getwarmups.php'),
      SET_TRAINER_WARMUP: URL('user/TRAINER_setwarmup.php')
    }
  }
}
