import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { CategoriesResponse } from '../models/interfaces/categories-response';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private http: HttpClient) { }

  getAllCategories() {
    return this.http.post<CategoriesResponse>(API.USER.TRAINER.CATEGORIES, null);
  }
}
