import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { ExercisesResponse } from '../models/interfaces/exercises-response';

@Injectable({
  providedIn: 'root'
})
export class ExercisesService {

  constructor(private http: HttpClient) { }

  getAllExercises() {
    return this.http.post<ExercisesResponse>(API.USER.TRAINER.EXERCISES, null);
  }
}
