import { Component, OnInit } from '@angular/core';
import { TrainingStatus } from 'src/app/core/enums/training-status.enum';
import { TrainingDataService } from 'src/app/core/services/training-data.service';

@Component({
  selector: 'app-training-status',
  templateUrl: './training-status.component.html',
  styleUrls: ['./training-status.component.scss']
})
export class TrainingStatusComponent implements OnInit {
  trainingStatus?: TrainingStatus;

  constructor(private trainingDataService: TrainingDataService) { }

  ngOnInit(): void {
    this.trainingDataService.trainingData.subscribe(trainingData => {
      this.trainingStatus = trainingData.TRAINING_STATUS;
    });
  }

  isTrainingStatusNew() {
    return TrainingStatus.NEW == this.trainingStatus;
  }

  isTrainingStatusDraft() {
    return TrainingStatus.DRAFT == this.trainingStatus;
  }

  isTrainingStatusCompleted() {
    return TrainingStatus.COMPLETED == this.trainingStatus;
  }

}
