

<div class="container" fxLayout="row" fxLayoutAlign="center">


  <div *ngIf="isLoading" fxLayoutAlign="center center">
    <app-preloader></app-preloader>
  </div>

      
  

<div *ngIf="!isLoading" class="container">

  <mat-card class="example-card">
    <mat-card-header>
      <mat-card-title>Trainings</mat-card-title>
      <mat-card-subtitle>Pending trainings</mat-card-subtitle>
      <button mat-icon-button aria-label="Refresh" (click)="refresh()">
              
        <mat-icon mat-list-icon>refresh</mat-icon>
        </button>
    </mat-card-header>
    
    <mat-card-content>

  <table mat-table
  [dataSource]="trainings" multiTemplateDataRows
  class="mat-elevation-z8">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
  <th mat-header-cell *matHeaderCellDef> {{column}} </th>
  <td mat-cell *matCellDef="let element">
    
    {{element[column]}} 
    <span *ngIf="'VIEW'===column"><button mat-button (click)="openTraining(element)">Edit</button></span>
    </td>
  </ng-container>
  
  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
  <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
  <div class="example-element-detail"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
   <div class="example-element-diagram">
     <div class="example-element-position"> {{element.position}} </div>
     <div class="example-element-symbol"> {{element.symbol}} </div>
     <div class="example-element-name"> {{element.name}} </div>
     <div class="example-element-weight"> {{element.weight}} </div>
   </div>
   <div class="example-element-description">
     {{ DDD(element.TRAINING_FORM) | json }}
     <!-- <button (click)="openCreateTraining(element)">Create training</button> -->


   </div>
  </div>
  </td>
  </ng-container>
  
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
  class="example-element-row"
  [class.example-expanded-row]="expandedElement === element"
  (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>


  

</mat-card-content>
</mat-card>
</div>
  

  
  
  
</div>

