<mat-card class="cont mat-elevation-z8">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex fxLayoutAlign="center center">
      <button  mat-button matTooltip="Back to pending trainings" [matTooltipShowDelay]="500" (click)="back()">
        <mat-icon>keyboard_return</mat-icon>
        &nbsp;
        <mat-label>Back</mat-label>
      </button>
    </div>
    <mat-divider [vertical]="true" ></mat-divider>
    <div fxFlex></div>
    <mat-card-header [ngSwitch]="entry">
        <mat-card-title *ngSwitchCase="Entry.TRAINING">Training</mat-card-title>
        <mat-card-title *ngSwitchCase="Entry.WARMUP">Warmup</mat-card-title>
        <mat-card-title *ngSwitchDefault>Menu</mat-card-title>
        <br>
        <mat-card-subtitle *ngSwitchCase="Entry.TRAINING">
          Create training
        </mat-card-subtitle>
        <mat-card-subtitle *ngSwitchCase="Entry.WARMUP">
          Create warmup
        </mat-card-subtitle>
    </mat-card-header>
    <div fxFlex></div>
    <div fxFlex></div>
    <app-training-status fxFlex>
    </app-training-status>
    <div fxFlex></div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxLayout="row wrap" fxLayoutAlign="space-evenly center">
      <button mat-button [disabled]="isButtonDisabled()" [matTooltipShowDelay]="500"
       matTooltip="Start from scratch" (click)="reset()">
        <mat-icon>clear</mat-icon>
        &nbsp;
        <mat-label>Clear</mat-label>
      </button>
      <button mat-button [disabled]="isButtonDisabled()" [matTooltipShowDelay]="500"
       matTooltip="Load last save" (click)="restore()">
        <mat-icon>restore</mat-icon>
        &nbsp;
        <mat-label>Restore</mat-label>
      </button>
      <button mat-button [disabled]="isButtonDisabled()" [matTooltipShowDelay]="500"
       matTooltip="Save changes" (click)="save()">
        <mat-icon>save</mat-icon>
        &nbsp;
        <mat-label>Save</mat-label>
      </button>
      <button mat-button [disabled]="isButtonDisabled()" [matTooltipShowDelay]="500"
       matTooltip="Mark training as completed" (click)="done()"
       *ngIf="entry === Entry.TRAINING">
        <mat-icon>done</mat-icon>
        &nbsp;
        <mat-label>Done</mat-label>
      </button>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div fxFlex></div>
  </div>
  <app-menu-progress-bar>
  </app-menu-progress-bar>
</mat-card>
