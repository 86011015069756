import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { WarmupDataGetRequest } from '../models/classes/warmup-data-get-request';
import { WarmupDataSetRequest } from '../models/classes/warmup-data-set-request';
import { WarmupDataSetResponse } from '../models/interfaces/warmup-data-set-response';
import { WarmupsResponse } from '../models/interfaces/warmups-response';

@Injectable({
  providedIn: 'root'
})
export class WarmupsService {

  constructor(private http: HttpClient) {
  }
  
  getTrainerWarmups(warmupDataGet: WarmupDataGetRequest) {
    return this.http.post<WarmupsResponse>(API.USER.TRAINER.GET_TRAINER_WARMUPS, warmupDataGet);
  }

  setTrainerWarmups(warmupDataSet: WarmupDataSetRequest) {
    return this.http.post<WarmupDataSetResponse>(API.USER.TRAINER.SET_TRAINER_WARMUP, warmupDataSet);
  }

}
