import { Component, Input, OnInit } from '@angular/core';
import { TrainingStateService } from 'src/app/core/services/training-state.service';

@Component({
  selector: 'app-menu-progress-bar',
  templateUrl: './menu-progress-bar.component.html',
  styleUrls: ['./menu-progress-bar.component.scss']
})
export class MenuProgressBarComponent implements OnInit {
  closeProgress: number = 0;
  closeTimeLeft: number = 10000;

  constructor(public trainingStateService: TrainingStateService) { }

  ngOnInit(): void {
    this.trainingStateService.closeProgress().subscribe(closeProgress => {
      this.closeProgress = closeProgress;
    });
    this.trainingStateService.closeTimeLeft().subscribe(closeTimeLeft => {
      this.closeTimeLeft = closeTimeLeft;
    });
  }

}
