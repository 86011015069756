


<div class="container" fxLayout="row" fxLayoutAlign="center center" fxFlexFill>


  <!-- <div *ngIf="isLoading" fxLayoutAlign="center center">
    <app-preloader></app-preloader>
  </div>

      
   -->

<div *ngIf="!isLoading" class="card-container">

  <mat-card class="example-card" >
    <mat-card-header>
      <mat-card-title>Categories</mat-card-title>
      <mat-card-subtitle>Details</mat-card-subtitle>
      <button mat-icon-button aria-label="Refresh" (click)="refresh()">
              
        <mat-icon mat-list-icon>refresh</mat-icon>
        </button>
    </mat-card-header>
    
    <mat-card-content class="example-card-content"> 


  
      <app-custom-expandable-table
      [columnsToDisplay] = "columns"
      [tableData] = "categories"></app-custom-expandable-table>



    </mat-card-content>
  </mat-card>
</div>
  

  
  
  
</div>

