import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../constants/api';
import { TempoResponse } from '../models/interfaces/tempo-response';

@Injectable({
  providedIn: 'root'
})
export class TempoService {

  constructor(private http: HttpClient) { }

  getTempoList() {
    return this.http.post<TempoResponse>(API.USER.TRAINER.TEMPO_LIST, null);
  }
}
