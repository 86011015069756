<div [ngSwitch]="entry">
  <div *ngSwitchCase="Entry.TRAINING">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Warmup:
          </mat-panel-title>
          <mat-panel-description>
            <div fxFlex></div>
            <mat-icon>directions_run</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-form-field appearance="fill" style="width: 120px; top: 10px;" *ngIf="warmup.length === 0">
          <mat-label>Warmups</mat-label>
          <mat-select [(value)]="warmup" (selectionChange)="updateWarmupBin($event.value)">
             <mat-option *ngFor="let trainerWarmup of warmups" [value]="getWarmupFromData(trainerWarmup.WARMUP_DATA)">
              {{ trainerWarmup.WARMUP_NAME }}
             </mat-option>
           </mat-select>
         </mat-form-field>
    
   



         <div fxLayout="row" class="example-box" *ngFor="let item of basket2; let i = index">
      <div fxLayout="column" fxFill>
        <div style="padding-bottom: 10px;">
           <div fxLayout="row" fxLayoutAlign="space-around stretch">
                <div fxFlex="15" fxLayout="row" fxLayoutAlign="center center">
                  <div>
                    <span>Exercise</span>
                      &nbsp;
                    <span>{{ (i < 10 ? '0' + (i + 1) : (i + 1)) }}</span>
                  </div>
                  <div fxLayout="row">
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <mat-icon *ngIf="1 == item.EXERCISE_HAVE_PHOTO"
                      [matTooltipShowDelay]="500"
                      matTooltip="Exercise with photo(s)">
                      photo_camera
                    </mat-icon>
                    <mat-icon *ngIf="1 == item.EXERCISE_HAVE_VIDEO"
                      [matTooltipShowDelay]="500"
                      matTooltip="Exercise with video(s)">
                      videocam
                    </mat-icon>
                  </div>
                </div>
                
                <mat-divider [vertical]="true" ></mat-divider>

                <div fxFlex="35" fxLayout="row" fxLayoutAlign="center center">
                  <div>
                    {{item.EXERCISE_NAME}}
                  </div>
                </div>
                
                <mat-divider [vertical]="true" ></mat-divider>

                <div fxFlex="35" fxLayout="row" fxLayoutAlign="space-evenly center">
                    <mat-form-field appearance="fill" style="width: 120px; top: 10px;">
                  <mat-label>Tempo</mat-label>
                    <mat-select [(value)]="warmup[i].tempo">
                       <mat-option *ngFor="let tempo of tempos" [value]="tempo.TEMPO">
                        {{ tempo.TEMPO }}
                       </mat-option>
                     </mat-select> 
                   </mat-form-field>
  
                    <mat-form-field appearance="fill" style="width: 120px; top: 10px;">
                    <mat-label>Delay [seconds]</mat-label>
                     <mat-select [(value)]="warmup[i].series_delay">
                        <mat-option *ngFor="let delay of series_delay" [value]="delay.DELAY">
                         {{ delay.DELAY }}
                        </mat-option>
                      </mat-select> 
                    </mat-form-field>
                </div>
                
                <mat-divider [vertical]="true" ></mat-divider>

                <div fxFlex="15" fxLayout="row" fxLayoutAlign="center center">
                  <button mat-icon-button aria-label="Remove exercise" (click)="removeWrm(i)">
                    <mat-icon >delete</mat-icon>
                  </button>
                </div>
           </div>
        </div>
        <div>
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Series:
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <mat-list dense role="list">
                    <ol>
                      <li fxLayout="row" fxLayoutAlign="space-around center" *ngFor="let series of getRepeatsForExerciseWrm(i); let j = index">
                        <div fxFlex="30">
                          <span  fxLayout="row" fxLayoutAlign="center center">
                            <app-repeat-type
                              [(repeats)]="series.repeats"
                              [(minutes)]="series.minutes">
                            </app-repeat-type>
                          </span>
                        </div>
                        <div fxFlex="55">
                          <span> 
                            <label>Intensity:</label>
                            &nbsp;&nbsp;&nbsp;
                            <mat-slider
                            (mouseover)="dragShield=true" (mouseout)="dragShield=false"
                            [value]="getIntensityIndex(series.intensivity_scale)"
                            (change)="series.intensivity_scale = getIntensityScale($event)"
                              [min]="1" [max]="getIntensityMaxRange()" [step]="1"></mat-slider>
                            &nbsp;&nbsp;&nbsp;
                            <label> {{ series.intensivity_scale }} </label>
                              - 
                            <label> {{ getIntensityShortDescription(series.intensivity_scale) }} </label>
                          </span>
                        </div>
                        <div fxFlex="15">
                          <span>
                            <button mat-icon-button
                            [disabled]="getRepeatsForExerciseWrm(i).length < 2" (click)="removeSeriesWrm(i, j)">
                            <mat-icon>clear</mat-icon>
                            </button>
                          </span>
                        </div>
                      </li>
                    </ol>
                  </mat-list>
                  <div fxLayout="row" fxFill fxLayoutAlign="center center">
                      <button fxFlex="80" mat-stroked-button aria-label="Add series" (click)="addSeriesWrm(i)">
                        <mat-icon >add</mat-icon>
                      </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
        </div>
      </div>
   </div>







    
    
      </mat-expansion-panel>
      
    
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Training:
          </mat-panel-title>
          <mat-panel-description>
            <div fxFlex></div>
            <mat-icon>sports</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
    
        <ng-container *ngTemplateOutlet="dayTemplate"></ng-container>
        
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngSwitchCase="Entry.WARMUP">
    <ng-container *ngTemplateOutlet="dayTemplate"></ng-container>
  </div>
  <div *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="dayTemplate"></ng-container>
  </div>
<div>


<ng-template #dayTemplate>
  
<div cdkDropList fxLayout="column"

[id]="'training-day-drop-list-'+day"
                    [cdkDropListData]="basket"
                    
                    (cdkDropListDropped)="drop($event)"

                    [ngClass]="basket.length > 0 ? 'example-list' : 'drop-box'"
                   
>

  <div class = "init-box" fxLayout="column" fxFlex *ngIf="basket.length === 0" >
    <div fxLayout="row"  fxLayoutAlign="space-between center">
      <mat-icon>south_east</mat-icon>
      <mat-icon>south_west</mat-icon>
    </div>
    <div  fxLayout="column" fxFlex fxLayoutAlign="space-evenly center">
        <div></div>
        <mat-icon class="drop-here-big-icon">save_alt</mat-icon>
        <span>
          Drop item here to create training for day {{ day }}.
        </span>
        <div></div>
    </div>
    <div  fxLayout="row"  fxLayoutAlign="space-between center">
      <mat-icon>north_east</mat-icon>
      <mat-icon>north_west</mat-icon>
    </div>
  </div>
    <div fxLayout="row" class="example-box" *ngFor="let item of basket; let i = index"
         cdkDrag [cdkDragDisabled]="dragShield" 
         cdkDragLockAxis="y">
      <div fxLayout="column" fxFill>
        <div style="padding-bottom: 10px;">
           <div fxLayout="row" fxLayoutAlign="space-around stretch">
                <div fxFlex="15" fxLayout="row" fxLayoutAlign="center center">
                  <div>
                    <span>Exercise</span>
                      &nbsp;
                    <span>{{ (i < 10 ? '0' + (i + 1) : (i + 1)) }}</span>
                  </div>
                  <div fxLayout="row">
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <mat-icon *ngIf="1 == item.EXERCISE_HAVE_PHOTO"
                      [matTooltipShowDelay]="500"
                      matTooltip="Exercise with photo(s)">
                      photo_camera
                    </mat-icon>
                    <mat-icon *ngIf="1 == item.EXERCISE_HAVE_VIDEO"
                      [matTooltipShowDelay]="500"
                      matTooltip="Exercise with video(s)">
                      videocam
                    </mat-icon>
                  </div>
                </div>
                
                <mat-divider [vertical]="true" ></mat-divider>

                <div fxFlex="35" fxLayout="row" fxLayoutAlign="center center">
                  <div>
                    {{item.EXERCISE_NAME}}
                  </div>
                </div>
                
                <mat-divider [vertical]="true" ></mat-divider>

                <div fxFlex="35" fxLayout="row" fxLayoutAlign="space-evenly center">
                  <mat-form-field appearance="fill" style="width: 120px; top: 10px;">
                    <mat-label>Tempo</mat-label>
                    <mat-select [(value)]="test2[i].tempo">
                       <mat-option *ngFor="let tempo of tempos" [value]="tempo.TEMPO">
                        {{ tempo.TEMPO }}
                       </mat-option>
                     </mat-select>
                   </mat-form-field>
  
                   <mat-form-field appearance="fill" style="width: 120px; top: 10px;">
                     <mat-label>Delay [seconds]</mat-label>
                     <mat-select [(value)]="test2[i].series_delay">
                        <mat-option *ngFor="let delay of series_delay" [value]="delay.DELAY">
                         {{ delay.DELAY }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
                
                <mat-divider [vertical]="true" ></mat-divider>

                <div fxFlex="15" fxLayout="row" fxLayoutAlign="center center">
                  <button mat-icon-button aria-label="Remove exercise" (click)="remove(i)">
                    <mat-icon >delete</mat-icon>
                  </button>
                </div>
           </div>
        </div>
        <div>
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Series:
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <mat-list dense role="list">
                    <ol>
                      <li fxLayout="row" fxLayoutAlign="space-around center" *ngFor="let series of getRepeatsForExercise(i); let j = index">
                        <div fxFlex="30">
                          <span fxLayout="row" fxLayoutAlign="center center">
                            <app-repeat-type
                              [(repeats)]="series.repeats"
                              [(minutes)]="series.minutes">
                            </app-repeat-type>
                          </span>
                        </div>
                        <div fxFlex="55">
                          <span> 
                            <label>Intensity:</label>
                            &nbsp;&nbsp;&nbsp;
                            <mat-slider
                            (mouseover)="dragShield=true" (mouseout)="dragShield=false"
                            [value]="getIntensityIndex(series.intensivity_scale)"
                            (change)="series.intensivity_scale = getIntensityScale($event)"
                              [min]="1" [max]="getIntensityMaxRange()" [step]="1"></mat-slider>
                            &nbsp;&nbsp;&nbsp;
                            <label> {{ series.intensivity_scale }} </label>
                              - 
                            <label> {{ getIntensityShortDescription(series.intensivity_scale) }} </label>
                          </span>
                        </div>
                        <div fxFlex="15">
                          <span>
                            <button mat-icon-button
                            [disabled]="getRepeatsForExercise(i).length < 2" (click)="removeSeries(i, j)">
                            <mat-icon>clear</mat-icon>
                            </button>
                          </span>
                        </div>
                      </li>
                    </ol>
                  </mat-list>
                  <div fxLayout="row" fxFill fxLayoutAlign="center center">
                      <button fxFlex="80" mat-stroked-button aria-label="Add series" (click)="addSeries(i)">
                        <mat-icon >add</mat-icon>
                      </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
        </div>
      </div>
   </div>
</div>

</ng-template>

