<h2 mat-dialog-title> 
  <span *ngIf="data === status.BAD_USER_OR_PASSWORD"> <mat-icon>error_outline</mat-icon> Bad User Or Password </span>
  <span *ngIf="data === status.BAD_USER_TYPE"> <mat-icon>warning_amber</mat-icon> Bad User Type </span>
</h2>
<mat-dialog-content class="mat-typography">
  <p *ngIf="data === status.BAD_USER_OR_PASSWORD">
    Please log in using valid e-mail and password.
  </p>
  <p *ngIf="data === status.BAD_USER_TYPE">
    Only trainers allowed.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
