<div class="container">
  <h3>
    <span>Account</span>
  </h3>
</div>
<div>
  <span>Trainer</span>
  &nbsp;
  <span>{{ user.name }}</span>
  &nbsp;
  <span>{{ user.surname }}</span>
</div>