import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { TrainingDayDropListId, EXERCISES_DROP_PREFIX, TRAINING_DAY_DROP_PREFIX } from 'src/app/core/constants/globals';
import { Error } from 'src/app/core/enums/error.enum';
import { ResponseStatus } from 'src/app/core/enums/response-status.enum';
import { TrainingWeekDay } from 'src/app/core/enums/training-week-day.enum';
import { Category } from 'src/app/core/models/interfaces/category';
import { Exercise } from 'src/app/core/models/interfaces/exercise';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { ExercisesService } from 'src/app/core/services/exercises.service';
import { TrainingDataService } from 'src/app/core/services/training-data.service';



@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss']
})
export class ExercisesComponent implements OnInit {
  isLoading: boolean = true;
  categories: Category[] = [];
  allExercises: Exercise[] = [];
  trainingDayDropIds: TrainingDayDropListId[] = [];
  exercisesWithPhoto: Exercise[] = [];
  exercisesWithVideo: Exercise[] = [];
  exercisesWithPhotoAndVideo: Exercise[] = [];
  get exercisesDropPrefix() {
    return EXERCISES_DROP_PREFIX;
  }
  filter: FormGroup;

  constructor(
    private snackBar: MatSnackBar,
    private categoriesService: CategoriesService,
    private exercisesService: ExercisesService,
    private trainingDataService: TrainingDataService,
    private fb: FormBuilder) {
      this.filter = this.fb.group({
        showOnlyPhotos: false,
        showOnlyVideos: false,
      });
  }

  ngOnInit(): void {
    this.trainingDataService.numberOfTrainingDays.subscribe(days => {
      this.trainingDayDropIds = [];
      for (let index = 0; index < days; index++) {
        const dropId: TrainingDayDropListId = `${TRAINING_DAY_DROP_PREFIX}${Object.values(TrainingWeekDay)[index]}` as const;
        this.trainingDayDropIds.push(dropId)
      }
    });
    
    const categories = this.categoriesService.getAllCategories();
    const exercises = this.exercisesService.getAllExercises();
    
    forkJoin([categories, exercises]).pipe()
    .subscribe(result => {
      if (result) {
        let errorsLog = new Array();
        let errors = new Array(); 
        let logMessages = new Array("Get categories response status: ", "Get exercises response status: "); 
        if (ResponseStatus.SUCCESS != result[0].response_status) {
          errorsLog.push(logMessages[0] + result[0].response_status);
          errors.push(Error.NO_CATEGORIES);
        }
        if (ResponseStatus.SUCCESS != result[1].response_status) {
          errorsLog.push(logMessages[1] + result[1].response_status);
          errors.push(Error.NO_EXERCISES);
        }
        if (errors.length > 0) {
          throw { errors, errorsLog };
        }
        this.categories = result[0].categories;
        this.allExercises = result[1].exercises;
        this.setFilteredExercises(result[1].exercises);
        this.isLoading = false;
      }
    }, error => {
      this.isLoading = false;
      this.snackBar.open(error.errors.join(", "), "Ok", { duration: 10 * 1000 });
      console.log(error.errorsLog.join(", "), "|" , error.errors.join(", "));
      throw error;
    });
  }

  setFilteredExercises(exercises: Exercise[]) {
    this.exercisesWithPhoto = exercises.filter(e => e.EXERCISE_HAVE_PHOTO == 1);
    this.exercisesWithVideo = exercises.filter(e => e.EXERCISE_HAVE_VIDEO == 1);
    this.exercisesWithPhotoAndVideo = Array.from(new Set([...this.exercisesWithPhoto, ...this.exercisesWithVideo]));
  }
  
  getExercisesToDisplay() : Array<Exercise> {
    if (this.filter.value.showOnlyPhotos && !this.filter.value.showOnlyVideos) {
      return this.exercisesWithPhoto;
    }
    if (this.filter.value.showOnlyVideos && !this.filter.value.showOnlyPhotos) {
      return this.exercisesWithVideo;
    }
    if (this.filter.value.showOnlyPhotos && this.filter.value.showOnlyVideos) {
      return this.exercisesWithPhotoAndVideo;
    }
    return this.allExercises;
  }

  getExercisesForCategory(categoryId: number) : Array<Exercise> {
    return this.getExercisesToDisplay().filter(e => {
      return e.EXERCISE_CATEGORY_ID.split(",").includes(categoryId.toString());
    }).sort((a, b) => {
      return a.EXERCISE_NAME.localeCompare(b.EXERCISE_NAME);
    });
  }
}
