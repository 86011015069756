export enum UserType {
  A = 0b0001,
  B = 0b0010,
  TRAINER = 0b0100,
  NUTRITIONIST = 0b1000,
  TRAINER_NUTRITIONIST = 0b1100
}

//A =  1 << 1,
//B =  1 << 2,
//AB =  A | B,