import { NgModule } from '@angular/core';
import { HomePageRoutingModule } from './home-page-routing.module';
import { HomePageComponent } from './home-page.component';
import { SharedModule } from '../shared/shared.module';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ExercisesComponent } from './components/exercises/exercises.component';
import { DialogContentConfirmActionComponent } from './components/dialog-content-confirm-action/dialog-content-confirm-action.component';
import { DialogContentConfirmLeavingPageComponent } from './components/dialog-content-confirm-leaving-page/dialog-content-confirm-leaving-page.component';
import { TrainingDayDraftComponent } from './components/training-day-draft/training-day-draft.component';
import { SetupExercisesComponent } from './components/setup-exercises/setup-exercises.component';
import { SetupCategoriesComponent } from './components/setup-categories/setup-categories.component';
import { TrainingComponent } from './components/training/training.component';
import { TrainingMenuComponent } from './components/training-menu/training-menu.component';
import { TrainingDraftComponent } from './components/training-draft/training-draft.component';
import { TrainingStatusComponent } from './components/training-status/training-status.component';
import { MenuProgressBarComponent } from './components/menu-progress-bar/menu-progress-bar.component';
import { WarmupListComponent } from './components/warmup-list/warmup-list.component';
import { WarmupComponent } from './components/warmup/warmup.component';
import { WarmupDraftComponent } from './components/warmup-draft/warmup-draft.component';
import { RepeatTypeComponent } from './components/repeat-type/repeat-type.component';



@NgModule({
  declarations: [
    HomePageComponent,
    AccountDetailsComponent,
    TrainingsComponent,
    CategoriesComponent,
    ExercisesComponent,
    DialogContentConfirmActionComponent,
    DialogContentConfirmLeavingPageComponent,
    TrainingDayDraftComponent,
    SetupExercisesComponent,
    SetupCategoriesComponent,
    TrainingComponent,
    TrainingMenuComponent,
    TrainingDraftComponent,
    TrainingStatusComponent,
    MenuProgressBarComponent,
    WarmupListComponent,
    WarmupComponent,
    WarmupDraftComponent,
    RepeatTypeComponent
  ],
  imports: [
    HomePageRoutingModule,
    SharedModule
  ]
})
export class HomePageModule { }
