import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MenuAction } from 'src/app/core/enums/menu-action.enum';
import { Entry } from 'src/app/core/enums/entry.enum';
import { ResponseStatus } from 'src/app/core/enums/response-status.enum';
import { WarmupDataSetRequest } from 'src/app/core/models/classes/warmup-data-set-request';
import { Warmup } from 'src/app/core/models/interfaces/warmup';
import { WarmupDay } from 'src/app/core/models/interfaces/warmup-day';
import { WarmupDataService } from 'src/app/core/services/warmup-data.service';
import { WarmupsService } from 'src/app/core/services/warmups.service';
import { DialogContentConfirmActionComponent } from '../dialog-content-confirm-action/dialog-content-confirm-action.component';
import { WarmupDraftComponent } from '../warmup-draft/warmup-draft.component';

@Component({
  selector: 'app-warmup',
  templateUrl: './warmup.component.html',
  styleUrls: ['./warmup.component.scss']
})
export class WarmupComponent implements OnInit, OnDestroy {
  private warmupId: string | null;
  public entry = Entry;

  showSidebar: boolean = true;
  sidebarSize: string = '30%';
  
  @ViewChild(WarmupDraftComponent) warmupgDraftC?: WarmupDraftComponent;

  warmup: Warmup | undefined;
  
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private warmupsService: WarmupsService,
    private warmupDataService: WarmupDataService,) {
      this.warmupId = null;
    }

  ngOnInit(): void {
    this.warmupDataService.warmupData.pipe(takeUntil(this.destroy$)).subscribe(warmup => {
      this.warmupId = this.route.snapshot.paramMap.get('warmupId');
      if (this.warmupId) {
        this.warmup = warmup;
      } else {
        this.warmup = undefined;
      }
    });
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.warmupDataService.changeWarmupData({} as Warmup);
  }

  toggleSidebar() {
    if (this.showSidebar) {
      this.sidebarSize = "0%";
    } else {
      this.sidebarSize = "30%";
    }
    this.showSidebar = !this.showSidebar;
  }

  isContentChanged() {
    // return this.contentChanged;
  }

  setContentChanged(contentChanged: boolean) {
    // this.contentChanged = contentChanged;
  }

  handleMenuAction(menuAction: MenuAction) {
    switch (menuAction) {
      case MenuAction.BACK:
       this.backToWarmups();
        break;
      case MenuAction.CLEAR:
        this.reset();
        break;
      case MenuAction.RESTORE:
        this.restore();
        break;
      case MenuAction.SAVE:
        this.save();
        break;
      case MenuAction.DONE:
      default:
        break;
    }
  }

  handleConfirmationDialog(action: MenuAction) {
    const confirmationDialogRef = this.dialog.open(DialogContentConfirmActionComponent, { data: action });
    return confirmationDialogRef.afterClosed().pipe(take(1));
  }

  backToWarmups() {
    this.router.navigate(["home/warmups"]);
  }

  reset() {
    this.handleConfirmationDialog(MenuAction.CLEAR).subscribe(result => {
      if (result) {
        this.warmupDataService.clearDraftData();
        this.snackBar.open("Training cleared", "Ok", { duration: 3 * 1000 });
      }
      this.setContentChanged(true);
    });
  }

  restore() {
    this.handleConfirmationDialog(MenuAction.RESTORE).subscribe(result => {
      if (result) {
        if (this.warmupId) {
          if (this.warmup) {
            
            this.warmupDataService.changeWarmupData(this.warmup);

          // this.trainingStateService.changeLoading(true);
         

            // this.trainingStateService.changeLoading(false);
            this.snackBar.open("Warmup restored", "Ok", { duration: 3 * 1000 });
            this.setContentChanged(true);


          }
        }
      }
    });
  }

  save() {
    if (this.warmupgDraftC) {
      this.warmupgDraftC.warmupName.markAsTouched();
      if (this.warmupgDraftC.warmupName.valid) {
        this.handleConfirmationDialog(MenuAction.SAVE).subscribe(result => {
          if (result) {
            //   this.trainingStateService.changeSaving(true);
            const draft: WarmupDay = {
              warmup: []
            }
            if (this.warmupgDraftC && this.warmupgDraftC.warmupDay) {
              draft.warmup = this.warmupgDraftC.warmupDay.training;
              const encodedData = btoa(JSON.stringify(draft));
                        
              const user = JSON.parse(localStorage.getItem('user')!);
              let warmupId: string;
              let trainerId: string;
              const warmupName = this.warmupgDraftC.warmupName.value
              if (this.warmup) {
                warmupId = this.warmup.ID;
                trainerId = this.warmup.TRAINER_ID
              } else {
                warmupId = '';
                trainerId = user.id.toString();
              }
              const warmupDataSetRequest = new WarmupDataSetRequest(warmupId, trainerId, encodedData, warmupName);
              this.warmupsService.setTrainerWarmups(warmupDataSetRequest).subscribe(result => {
                if (result) {
                  if (ResponseStatus.SUCCESS != result.response_status) {
                    // this.trainingStateService.changeLoading(false);
                    // this.snackBar.open(Error.NO_TRAINING_DATA, "Ok", { duration: 10 * 1000 });
                    // console.log(result.response_status, Error.NO_TRAINING_DATA);
                    // throw Error.NO_TRAINING_DATA;
                  }
                  // this.warmupDataService.changeWarmupData(result.);
                  // this.trainingStateService.changeLoading(false);
                  const newWarmup: Warmup = {
                    ID: warmupId,
                    TRAINER_ID: trainerId,
                    WARMUP_DATA: encodedData,
                    WARMUP_NAME: warmupName,
                  }
                  this.warmupDataService.changeWarmupData(newWarmup);
                  this.snackBar.open("Warmup saved", "Ok", { duration: 3 * 1000 });
                }
              }, error => {
                // this.trainingStateService.changeSaving(false);
                // this.snackBar.open(error.errors.join(", "), "Ok", { duration: 10 * 1000 });
                // console.log(error.errorsLog.join(", "), "|" , error.errors.join(", "));
                throw error;
              });


            }
      
          }
          // this.setContentChanged(false);
        });
      } else {
        
      }
    }
  }

}
